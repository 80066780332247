import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const evaluation_category_questionnaire_endpoint =
  "EvaluationCategoryQuestionnaireController";
const evaluation_fiche_endpoint = "EvaluationFicheController";
const evaluation_jalon_endpoint = "EvaluationJalonController";
const evaluation_questionnaire_endpoint = "EvaluationQuestionnaireController";
const evaluation_reponse_endpoint = "EvaluationReponseController";
const evaluation_saison_endpoint = "EvaluationSaisonController";
const evaluation_type_questionnaire_endpoint =
  "EvaluationTypeQuestionnaireController";
const evaluation_type_reponse_endpoint = "EvaluationTypeReponseController";
const service_endpoint = "Service";
const evaluation_quesiton_par_fiche_endpoint =
  "EvaluationQuestionnaireParFicheController";

export const evaluation_refonte_saison_service = {
  async getList(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_saison_endpoint + `/list/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getResultList(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_saison_endpoint + `/resultat/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getOne(id) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_saison_endpoint + `/find/`,
        {
          params: { id_objet: id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getActiveOne() {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_saison_endpoint + `/find_active_one/`
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_saison_endpoint + `/insert/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_saison_endpoint + `/update/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_saison_endpoint + `/delete/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
export const evaluation_refonte_fiche_evaluation_service = {
  async getList(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_fiche_endpoint + `/list/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getOne(id) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_fiche_endpoint + `/find/`,
        {
          params: { id_objet: id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getAllDetail(id) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_fiche_endpoint + `/find_all_detail/`,
        {
          params: { obj_id: id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getAllDetailByUserId(id) {
    try {
      const result = await axios.get(
        apiUrl +
          "/" +
          evaluation_fiche_endpoint +
          `/detail_fiche_evaluation_by_user_id/`,
        {
          params: { obj_id: id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_fiche_endpoint + `/insert/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insertThenAssignToFiche(obj) {
    try {
      const result = await axios.post(
        apiUrl +
          "/" +
          evaluation_fiche_endpoint +
          `/insert_then_assign_to_fiche/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_fiche_endpoint + `/update/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_fiche_endpoint + `/delete/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
export const evaluation_refonte_category_service = {
  async getList(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_category_questionnaire_endpoint + `/list/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getOne(id) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_category_questionnaire_endpoint + `/find/`,
        {
          params: { id_objet: id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_category_questionnaire_endpoint + `/insert/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_category_questionnaire_endpoint + `/update/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_category_questionnaire_endpoint + `/delete/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
export const evaluation_utilitises_service = {
  async getServiceList(id_direction) {
    try {
      const result = await axios.get(
        apiUrl + "/Service/getServiceByIdDirection/"+ id_direction
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
export const evaluation_refonte_type_questionnaire_service = {
  async getList(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_type_questionnaire_endpoint + `/list/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getOne(id) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_type_questionnaire_endpoint + `/find/`,
        {
          params: { id_objet: id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_type_questionnaire_endpoint + `/insert/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_type_questionnaire_endpoint + `/update/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_type_questionnaire_endpoint + `/delete/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
export const evaluation_refonte_jalon_service = {
  async getList(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_jalon_endpoint + `/list/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getListByFiche(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_jalon_endpoint + `/list_by_fiche/`, {params: {filtre : filtre}}
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getOne(id) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_jalon_endpoint + `/find/`,
        {
          params: { id_objet: id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_jalon_endpoint + `/insert/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_jalon_endpoint + `/update/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_jalon_endpoint + `/delete/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
export const evaluation_refonte_questionnaire_service = {
  async getList(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_questionnaire_endpoint + `/list/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getListByFiche(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_questionnaire_endpoint + `/list_by_fiche/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getOne(id) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_questionnaire_endpoint + `/find/`,
        {
          params: { id_objet: id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_questionnaire_endpoint + `/insert/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_questionnaire_endpoint + `/update/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_questionnaire_endpoint + `/delete/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
export const evaluation_refonte_reponse_service = {
  async getList(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_reponse_endpoint + `/list/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getOne(id) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_reponse_endpoint + `/find/`,
        {
          params: { id_objet: id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_reponse_endpoint + `/insert/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert_or_update(objs) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_reponse_endpoint + `/insert_or_update/`,
        objs
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_reponse_endpoint + `/update/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_reponse_endpoint + `/delete/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
export const evaluation_refonte_questionnaire_par_fiche_service = {
  async getList(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_quesiton_par_fiche_endpoint + `/list/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getQuestionnaireListPerFiche(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_quesiton_par_fiche_endpoint + `/list/`,
        {
          params: filtre,
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getListByQuestionnaireId(questionnaire_id) {
    try {
      const result = await axios.get(
        apiUrl +
          "/" +
          evaluation_quesiton_par_fiche_endpoint +
          `/list_by_question_id/`,
        {
          params: { questionnaire_id: questionnaire_id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getOne(id) {
    try {
      const result = await axios.get(
        apiUrl + "/" + evaluation_quesiton_par_fiche_endpoint + `/find/`,
        {
          params: { id_objet: id },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_quesiton_par_fiche_endpoint + `/insert/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert_or_delete(fiche_id, objs) {
    try {
      const result = await axios.post(
        apiUrl +
          "/" +
          evaluation_quesiton_par_fiche_endpoint +
          `/insert_suppression_groupe/`,
        {
          params: {
            id_fiche: fiche_id,
            objs: objs,
          },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert_or_update(objs) {
    try {
      const result = await axios.post(
        apiUrl +
          "/" +
          evaluation_quesiton_par_fiche_endpoint +
          `/insert_or_update/`,
        objs
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_quesiton_par_fiche_endpoint + `/update/`,
        obj
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete(obj) {
    try {
      const result = await axios.post(
        apiUrl + "/" + evaluation_quesiton_par_fiche_endpoint + `/delete/`,
        {
          params: { id_quesiton: obj },
        }
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
