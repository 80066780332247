import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Button ,TextField,FormControl, Select,MenuItem} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataRH } from "../../../data/modules/hr-header";
import { useNavigate } from 'react-router-dom';
import TimeTrackingService from "../../../services/TimeTrackingService";
import Title from "../../../components/Title";
import { CircularProgress } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { CreateTwoTone ,DeleteOutlineRounded} from "@material-ui/icons";
import Message from "../../tools/Message";
import CheckIcon from "@material-ui/icons/Send";
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        elevation: 0,
    },
}));

export default function TimeTrackingProvisional(props) {

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [isloading, setIsloading] = useState(false);
     const [message, setMessage] = useState({
            open: false,
            color: 'green',
            messageInfo: '',
          });
     const { open, color, messageInfo } = message;
 
    useEffect(() => {
        setIsloading(true);
        TimeTrackingService.getTimePrevisionaly().then((results) => {
            if (results) {
                const rowsWithIds = results.data.map(item => ({
                    ...item,
                    id: item.timetraking_id  
                }));
                setList(rowsWithIds);  
                setIsloading(false);
            } else {
                setList([]);
                setIsloading(false);
            }
        });
    }, []);
 
    function handleClick() {
        navigate('/timeTrackingAdd');
    }

    const handleenvoie = useCallback((id) => {
        setIsloading(true);
        TimeTrackingService.sendvalidate(id).then((results) => {
                    if (results) {
                        setMessage({...message, open: true, color: 'green', messageInfo: results.message});
                        TimeTrackingService.getTimePrevisionaly().then((results) => {
                            if (results) {
                                const rowsWithIds = results.data.map(item => ({
                                    ...item,
                                    id: item.timetraking_id  
                                }));
                                setList(rowsWithIds);  
                                setIsloading(false);
                            } else {
                                setList([]);
                                setIsloading(false);
                            }
                        });
                    } else {
                        setMessage({...message, open: true, color: 'red', messageInfo: results.message});
                    }
                });
      }, []);
      
      const handleEdit = useCallback((id) => {
        navigate('/timeTrackingAdd/'+id);
      }, []);
      
      const handleDelete = useCallback((id) => {
        TimeTrackingService.deleteTimePrevisionaly(id).then((results) => {
            if (results) {
                setMessage({...message, open: true, color: 'green', messageInfo: results.message});
                setIsloading(true);
                TimeTrackingService.getTimePrevisionaly().then((results) => {
                    if (results) {
                        const rowsWithIds = results.data.map(item => ({
                            ...item,
                            id: item.timetraking_id  
                        }));
                        setList(rowsWithIds);  
                        setIsloading(false);
                    } else {
                        setList([]);
                        setIsloading(false);
                    }
                });
            } else {
                setMessage({...message, open: true, color: 'red', messageInfo: results.message});
            }
        });
      }, []);

  

    const column = [
        {
            field: "name",
            headerName: "Nom et prénom(s)",
            width: 300,
        },
        {
            field: "date",
            headerName: "Date du pointage",
            width: 200,
        },
        {
            field: "observation",
            headerName: "Motif",
            width: 350,
        },
        {
            field: "validate",
            headerName: "Statut",
            width: 350,
          },
        {
            field: "status",
            headerName: "Action",
            width: 240,
            renderCell: (params) => {
                return (
                    <>
                    {params.row.validate === "Nouveau" && (
                      <strong>
                        <Button
                          title="Envoyez pour validation"
                          onClick={() => handleenvoie(params.row.id)} 
                        >
                          {isloading ? (
                                <CircularProgress size={24} style={{ color: "green", marginRight: 8 }} />
                            ) : (
                                <CheckIcon style={{ color: "green", marginRight: 8 }} />
                            )}
                            {isloading ? "Chargement..." : ""}
                            </Button>
                        <Button onClick={() => handleEdit(params.row.id)}> 
                          <CreateTwoTone style={{ color: "black" }} />
                        </Button>
                        <Button onClick={() => handleDelete(params.row.id)}> 
                          <DeleteOutlineRounded style={{ color: "red" }} />
                        </Button>
                      </strong>
                    )}
                  </>
                          
                );
              },
        },
    ];

    function handleCloseMessage(){
        setMessage({...message, open: false});
      }

    return (
        <MainContainer {...headerDataRH}>
            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <Grid container spacing={3}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClick}
                    style={{ fontWeight: 600 }}
                >
                    CREER 
                </Button>
            </Grid>
            <h1 style={{ textAlign: 'center', color:'#4682B4' }}>Liste des pointages manuels</h1>
            <Spacer y={2} />
            <Grid item xs={12}>
                <DataGrid
                    rows={list}  
                    columns={column}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    style={{ width: "100%", height: 500 }}  
                />

            </Grid>
        </MainContainer>
    );
}
