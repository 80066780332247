import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AutocompleteCombobox, {
  adaptDataValueToAutocompleteData,
} from "../shared/AutocompleteCombobox";
import { AddOutlined, EditOutlined } from "@mui/icons-material";
import CategoryCritereForm from "../category_criteria/CategoryCritereForm";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import { CloseOutlined } from "@material-ui/icons";
import useConfirm from "../../../hooks/useConfirm";
import {
  evaluation_refonte_category_service,
  evaluation_refonte_fiche_evaluation_service,
  evaluation_refonte_jalon_service,
  evaluation_refonte_questionnaire_service,
  evaluation_refonte_type_questionnaire_service,
} from "../../../services/PersonalEvaluationRefonteService";

const CritereForm = ({ isOpen, current_critere, handleClose }) => {
  /** ***********************************************STATE************************************************ */
  const { showToast, Toast } = useToast();
  const { DialogComponent, openConfirmDialog } = useConfirm();
  const [submitLoad, setLoadSUbmit] = useState(false);
  const [categeoryCritereFormOpen, setCategeoryCritereFormOpen] =
    useState(false);
  const [categoryCriteres, setCategoryCriteres] = useState([]);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [selectedCategoryCritere, setSelectedCategoryCritere] = useState({
    ID_CATEGORY: 0,
    CATEGORY_NAME: "",
  });
  const [selectedFicheEvaluation, setSelectedFicheEvaluation] = useState({
    ID_FICHE_EVALUATION: 0,
    LIBELE_FICHE: "",
    ID_POSTE: 0,
  });
  const [critere_actuel, setCritereActuel] = useState({
    ID_QUESTIONNAIRE: 0,
    ID_CATEGORY: 0,
    ID_TYPE_QUESTIONNAIRE: 0,
    CATEGORY_NAME: "",
    TYPE_NAME: "",
    QUESTION: "",
    ID_FICHE_EVALUATION: 0,
    NOTE_MAX: 5,
    NOTE_MIN: 1,
  });
  const [jalonReponses, setJalonReponses] = useState([]);
  const [selectedJalon, setSelectedJalon] = useState({
    ID_JALON: null,
    ID_QUESTIONNAIRE: critere_actuel.ID_QUESTIONNAIRE,
    VALEUR: "",
  });
  const [fichesEvaluations, setFicheEvaluations] = useState([]);

  /****************************************METHODES******************************************************** */

  const handleLoadFicheEvaluationList = () => {
    evaluation_refonte_fiche_evaluation_service
      .getList({ mot_cle: "" })
      .then((res) => {
        if (res && res.data) {
          setFicheEvaluations(res.data);
        }
      })
      .catch((e) => {
        showToast(TYPE_NOTIFICATION.ERROR, e.toString());
      });
  };

  const handleRefreshJalonList = (id_critere) => {
    evaluation_refonte_jalon_service
      .getList({ ID_QUESTIONNAIRE: id_critere })
      .then((res) => {
        if (res && res.data) {
          setJalonReponses(res.data || []);
        }
      });
  };

  const handleAddJalonResponse = () => {
    let new_jalons = jalonReponses.slice();
    new_jalons.push(selectedJalon);
    handleInsertJalon(selectedJalon);
    setJalonReponses(new_jalons);
    setSelectedJalon("");
  };

  const confirmDeleteJalon = (jalon) => {
    evaluation_refonte_jalon_service.delete(jalon).then((res) => {
      if (res && res.success) {
        handleRefreshJalonList(critere_actuel.ID_QUESTIONNAIRE);
        showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
      } else {
        showToast(TYPE_NOTIFICATION.ERROR, res.message);
      }
    });
  };

  const handleRemoveJalon = (idx, jalon) => {
    /*    let new_jalons = jalonReponses.slice();
    new_jalons.splice(idx, 1);
    setJalonReponses(new_jalons); */
    openConfirmDialog(
      "Confirmation",
      "Voullez vous vraiment supprimer cette reponse?",
      () => confirmDeleteJalon(jalon)
    );
  };

  const getInsertableCurrentCritere = () => {
    return {
      ID_QUESTIONNAIRE: critere_actuel.ID_QUESTIONNAIRE,
      ID_CATEGORY: critere_actuel.ID_CATEGORY,
      ID_TYPE_QUESTIONNAIRE: critere_actuel.ID_TYPE_QUESTIONNAIRE,
      QUESTION: critere_actuel.QUESTION,
      NOTE_MIN: critere_actuel.NOTE_MIN,
      NOTE_MAX: critere_actuel.NOTE_MAX,
      ID_FICHE_EVALUATION: selectedFicheEvaluation.ID_FICHE_EVALUATION,
    };
  };

  const isAllFieldOk = () => {
    let ok = false;
    if (!getInsertableCurrentCritere().ID_CATEGORY) {
      ok = false;
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Veuillez selectionner la catégorie du critère"
      );
    } else if (
      !getInsertableCurrentCritere().QUESTION ||
      getInsertableCurrentCritere().QUESTION.trim() === ""
    ) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Veuillez inserer le critere correspondant à la catégorie"
      );
      ok = false;
    } else if (!getInsertableCurrentCritere().ID_TYPE_QUESTIONNAIRE) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Veuillez selectionner le type de la reponse souhaité"
      );
      ok = false;
    } else if (getInsertableCurrentCritere().ID_TYPE_QUESTIONNAIRE) {
      if (
        critere_actuel.TYPE_NAME.includes("note") &&
        (!getInsertableCurrentCritere().NOTE_MIN ||
          !getInsertableCurrentCritere().NOTE_MAX)
      ) {
        ok = false;
        showToast(
          TYPE_NOTIFICATION.ERROR,
          "Veuillez verifier que vous avez ajouté le note min et note max"
        );
      } else {
        ok = true;
      }
    } else {
      ok = true;
    }
    return ok;
  };

  const reinitialiseCurrentCritere = () => {
    setCritereActuel({
      ID_QUESTIONNAIRE: 0,
      ID_CATEGORY: 0,
      ID_TYPE_QUESTIONNAIRE: 0,
      CATEGORY_NAME: "",
      TYPE_NAME: "",
      QUESTION: "",
      NOTE_MAX: 5,
      NOTE_MIN: 0,
    });
    setJalonReponses([]);
  };

  const handleChangeCategoryCritere = (new_value) => {
    console.log("category new", new_value);

    setCritereActuel({
      ...critere_actuel,
      ID_CATEGORY: new_value.ID_CATEGORY,
      CATEGORY_NAME: new_value.CATEGORY_NAME,
    });
    setSelectedCategoryCritere({
      ID_CATEGORY: new_value.ID_CATEGORY,
      CATEGORY_NAME: new_value.CATEGORY_NAME,
    });
  };
  const handleChangeTypeQuestion = (new_value) => {
    setCritereActuel({
      ...critere_actuel,
      ID_TYPE_QUESTIONNAIRE: new_value.ID_TYPE_QUESTIONNAIRE,
      TYPE_NAME: new_value.TYPE_NAME,
    });
  };

  const handleChangeQuestion = (evt) => {
    const { name, value } = evt.target;
    console.log("val", value, " name", name);

    setCritereActuel({ ...critere_actuel, [name]: value });
  };

  const handleDialogClose = () => {
    handleClose();
    reinitialiseCurrentCritere();
  };

  const handleSaveCritere = () => {
    if (isAllFieldOk()) {
      if (getInsertableCurrentCritere().ID_QUESTIONNAIRE > 0) {
        handleUpdateCritere();
      } else {
        handleInsertCritere();
      }
    }
  };

  const handleInsertCritere = (jalon) => {
    evaluation_refonte_questionnaire_service
      .insert(getInsertableCurrentCritere())
      .then((res) => {
        if (res && res.success) {
          if (jalon) {
            jalon.ID_QUESTIONNAIRE = res.data.ID_QUESTIONNAIRE;
            handleInsertJalon(jalon);
            handleRefreshJalonList(critere_actuel.ID_QUESTIONNAIRE);
          } else {
            handleDialogClose();
          }
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };
  const handleUpdateCritere = () => {
    evaluation_refonte_questionnaire_service
      .update(getInsertableCurrentCritere())
      .then((res) => {
        if (res && res.success) {
          handleDialogClose();
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };
  const handleInsertJalon = (jalon) => {
    evaluation_refonte_jalon_service
    .insert(jalon)
    .then((res) => {
      if (res && res.success) {
        handleRefreshJalonList(critere_actuel.ID_QUESTIONNAIRE);
        showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
      } else {
        showToast(TYPE_NOTIFICATION.ERROR, res.message);
      }
    });
  };

  const handlDeletetJalon = (id_jalon) => {
    evaluation_refonte_jalon_service.deleteJalon(id_jalon).then((res) => {
      if (res && res.success) {
        showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
      } else {
        showToast(TYPE_NOTIFICATION.ERROR, res.message);
      }
    });
  };

  const handleChangeJalon = (evt) => {
    const { name, value } = evt.target;
    setSelectedJalon({
      ...selectedJalon,
      ID_QUESTIONNAIRE: critere_actuel.ID_QUESTIONNAIRE,
      VALEUR: value,
    });
  };
  const handleDeleteJalon = (jalon) => {
    evaluation_refonte_jalon_service.deleteJalon(selectedJalon).then((res) => {
      if (res && res.success) {
        showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
      } else {
        showToast(TYPE_NOTIFICATION.ERROR, res.message);
      }
    });
  };

  const handleSaveJalon = () => {
    if (!critere_actuel.ID_QUESTIONNAIRE) {
      openConfirmDialog(
        "Confirmation",
        "Vous devez enregistrer cette critere d'abord, Voullez vous proceder à l'enregistrement?",
        () => {
          handleInsertCritere(selectedJalon);
        }
      );
    } else {
      handleAddJalonResponse();
    }
  };

  const handleRefreshCritereCategoryList = () => {
    evaluation_refonte_category_service
      .getList({ mot_cle: "" })
      .then((res) => {
        if (res && res.data) {
          setCategoryCriteres(res.data);
        }
      })
      .catch((e) => {
        console.log(e.toString());
      });
  };

  const handleRealoadCompsOfTheForm = () => {
    handleRefreshCritereCategoryList();
    handleLoadFicheEvaluationList();
    evaluation_refonte_type_questionnaire_service
      .getList(null)
      .then((res) => {
        if (res && res.data) {
          setQuestionTypes(res.data);
        }
      })
      .catch((e) => {
        console.log(e.toString());
      });
  };

  const handleChangeReponseJalon = (evt) => {
    const { value } = evt.target;
    setSelectedJalon({
      ...selectedJalon,
      VALEUR: value,
      ID_QUESTIONNAIRE: critere_actuel.ID_QUESTIONNAIRE,
    });
  };

  const handleClearAllField = () => {
    setSelectedCategoryCritere({ ID_CATEGORY: 0, CATEGORY_NAME: "" });
  };

  //****tsy azo kitiana */
  const handleClickNewCategory = () => {
    handleClearAllField();
    setCategeoryCritereFormOpen(true);
  };

  //
  const handleClickEditCategory = () => {
    setSelectedCategoryCritere({
      ID_CATEGORY: critere_actuel.ID_CATEGORY,
      CATEGORY_NAME: critere_actuel.CATEGORY_NAME,
    });
    setCategeoryCritereFormOpen(true);
  };

  const handleCloseFormDIalog = () => {
    refreshAll();
    handleRefreshCritereCategoryList();
    handleClearAllField();
    setCategeoryCritereFormOpen(false);
  };

  const handleChangeFicheCritere = (new_val) => {
    setSelectedFicheEvaluation(new_val);
    setCritereActuel({
      ...critere_actuel,
      ID_FICHE_EVALUATION: new_val.ID_FICHE_EVALUATION,
    });
  };

  const handleFechQuestionById = (id) => {
    evaluation_refonte_questionnaire_service.getOne(id).then((res) => {
      if (res && res.data) {
        setCritereActuel(res.data);
      }
    });
  };

  const refreshAll = () => {
    handleFechQuestionById(current_critere.ID_QUESTIONNAIRE);
    handleRefreshJalonList(current_critere.ID_QUESTIONNAIRE);
  };

  /***********************************************************HOOKS***************************************************************************************** */
  useEffect(() => {
    //setCritereActuel(current_critere);
    refreshAll();
    if (isOpen) {
      handleRealoadCompsOfTheForm();
    }
  }, [current_critere, isOpen]);

  return (
    <>
      <DialogComponent />
      {!isOpen && <Toast />}
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        {isOpen && <Toast />}
        <Paper>
          <CategoryCritereForm
            isOpen={categeoryCritereFormOpen}
            handleClose={handleCloseFormDIalog}
            current_category_critere={selectedCategoryCritere}
          />
          <Grid
            container
            height={"90vh"}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
          >
            <DialogTitle id="dialog-title" className="entete-dialog">
              {critere_actuel.ID_QUESTIONNAIRE === 0
                ? "Nouveau critère"
                : "Modifier critère"}
            </DialogTitle>
            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
              sx={{ maxHeight: "73vh", overflow: "auto" }}
            >
              <Grid flexDirection={"column"} mt={0.5}>
                <Typography className="form-control">
                  Catégorie critere *
                </Typography>
                <Grid
                  display={"flex"}
                  flexDirection={"row"}
                  alignContent={"center"}
                >
                  <Grid flexGrow={1}>
                    <AutocompleteCombobox
                      limitTags={2}
                      fullWidth
                      label="Catégorie critere *"
                      data={adaptDataValueToAutocompleteData(
                        categoryCriteres,
                        "CATEGORY_NAME",
                        "ID_CATEGORY"
                      )}
                      valeur={adaptDataValueToAutocompleteData(
                        categoryCriteres,
                        "CATEGORY_NAME",
                        "ID_CATEGORY"
                      ).find(
                        (category_critere) =>
                          category_critere.ID_CATEGORY ===
                          critere_actuel.ID_CATEGORY
                      )}
                      onChange={handleChangeCategoryCritere}
                    />
                  </Grid>
                  <Grid alignContent={"center"}>
                    <Button sx={{ ml: 0.5 }} onClick={handleClickNewCategory}>
                      <AddOutlined />
                    </Button>
                    <Button
                      sx={{ ml: 0.5 }}
                      disabled={!critere_actuel.ID_CATEGORY}
                      onClick={handleClickEditCategory}
                    >
                      <EditOutlined />
                    </Button>
                  </Grid>
                </Grid>
                <Typography className="form-control">Question *</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  name="QUESTION"
                  label="Question *"
                  onChange={(e) => handleChangeQuestion(e)}
                  value={critere_actuel.QUESTION}
                />
                <Typography className="form-control">
                  Fiche d'evaluation *
                </Typography>
                <Grid
                  display={"flex"}
                  flexDirection={"row"}
                  alignContent={"center"}
                >
                  <Grid flexGrow={1}>
                    <AutocompleteCombobox
                      limitTags={2}
                      fullWidth
                      label="Fiche d'evaluation *"
                      data={adaptDataValueToAutocompleteData(
                        fichesEvaluations,
                        "LIBELE_FICHE",
                        "ID_FICHE_EVALUATION"
                      )}
                      valeur={adaptDataValueToAutocompleteData(
                        fichesEvaluations,
                        "LIBELE_FICHE",
                        "ID_FICHE_EVALUATION"
                      ).find(
                        (category_critere) =>
                          category_critere.ID_FICHE_EVALUATION ===
                          critere_actuel.ID_FICHE_EVALUATION
                      )}
                      onChange={handleChangeFicheCritere}
                    />
                  </Grid>
                </Grid>
                <Typography className="form-control">
                  Type de la reponse *
                </Typography>
                <AutocompleteCombobox
                  limitTags={2}
                  fullWidth
                  label="Type de la reponse *"
                  valeur={adaptDataValueToAutocompleteData(
                    questionTypes,
                    "TYPE_NAME",
                    "ID_TYPE_QUESTIONNAIRE"
                  ).find(
                    (question_type) =>
                      question_type.ID_TYPE_QUESTIONNAIRE ===
                      critere_actuel.ID_TYPE_QUESTIONNAIRE
                  )}
                  data={adaptDataValueToAutocompleteData(
                    questionTypes,
                    "TYPE_NAME",
                    "ID_TYPE_QUESTIONNAIRE"
                  )}
                  onChange={handleChangeTypeQuestion}
                />
                <Grid
                  display={
                    String(critere_actuel.TYPE_NAME).includes("note")
                      ? "flex"
                      : "none"
                  }
                  flexDirection={"row"}
                >
                  <Grid item xs={6}>
                    <Typography className="form-control">Note Min *</Typography>
                    <TextField
                      label="Note Min"
                      size="small"
                      type="number"
                      value={critere_actuel.NOTE_MIN}
                      name="NOTE_MIN"
                      onChange={handleChangeQuestion}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="form-control">Note Max *</Typography>
                    <TextField
                      label="Note Max"
                      size="small"
                      value={critere_actuel.NOTE_MAX}
                      name="NOTE_MAX"
                      onChange={handleChangeQuestion}
                    />
                  </Grid>
                </Grid>
                <Grid
                  display={
                    String(critere_actuel.TYPE_NAME).includes("jalon")
                      ? "flex"
                      : "none"
                  }
                  flexDirection={"row"}
                >
                  <Grid item xs={12}>
                    <Typography className="form-control">
                      Entrer une reponse au choix
                    </Typography>
                    <Grid display={"flex"} flexDirection={"row"}>
                      <TextField
                        label="Reponse"
                        size="small"
                        value={selectedJalon.VALEUR || ""}
                        fullWidth
                        name="reponse_jalon"
                        onChange={handleChangeReponseJalon}
                        // onChange={(e) => console.log("Valeur saisie :", e.target.value)}
                      />
                      <Tooltip title="Cliquer pour ajouter la reponse">
                        <Button
                          disabled={
                            !selectedJalon.VALEUR ||
                            selectedJalon.VALEUR.trim() === ""
                          }
                          onClick={handleSaveJalon}
                        >
                          <AddOutlined />
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid
                      className="jalon_list"
                      item
                      mt={1}
                      xs={12}
                      maxHeight={"17vh"}
                      overflow={"auto"}
                    >
                      {jalonReponses.map((item, idx) => (
                        <>
                          <Paper key={idx}>
                            <Grid
                              key={idx}
                              m={1}
                              p={0.5}
                              display={"flex"}
                              flexDirection={"row"}
                            >
                              <Grid
                                flexGrow={1}
                                display={"flex"}
                                alignContent={"center"}
                              >
                                {item.VALEUR}
                              </Grid>
                              <Grid display={"flex"} alignContent={"center"}>
                                <Button
                                  size="small"
                                  onClick={() => handleRemoveJalon(idx, item)}
                                >
                                  <CloseOutlined />
                                </Button>
                              </Grid>
                            </Grid>
                          </Paper>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions>
              <LoadingButton
                loading={submitLoad}
                size="small"
                className="form_control"
                type="submit"
                onClick={handleSaveCritere}
                variant="contained"
              >
                Enregistrer
              </LoadingButton>
              <Button
                className="form_control"
                size="small"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Annuler
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default CritereForm;
