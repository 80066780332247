import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const top100Films = [{ label: "The Godfather", val: 1972 }];

export const adaptDataValueToAutocompleteData = (
  datalist,
  columnToBeShown,
  idToBeVal
) => {
  let new_data_list = [];
  datalist.map((data) => {
    data.label = data[columnToBeShown];
    //delete data[columnToBeShown];
    data.val = data[idToBeVal];
    data.key = data[idToBeVal];
    //delete data[idToBeVal];
    new_data_list.push(data);
  });
  return new_data_list;
};

const AutocompleteCombobox = ({
  stylex,
  class_name,
  id,
  label,
  data,
  onChange,
  placeholder,
  valeur,
  fullWidth = false,
  disable = false,
}) => {
  const [selectedValue, setSelectedValue] = React.useState(valeur || null);

  React.useEffect(() => {
    if (valeur) setSelectedValue(valeur);
  }, [valeur]);

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const uniqueOptions = Array.from(new Set(data.map((item) => item.val))).map(
    (val) => data.find((item) => item.val === val)
  );

  return (
    <Autocomplete
      disabled={disable}
      disablePortal
      fullWidth={fullWidth}
      size="small"
      id={id}
      options={uniqueOptions}
      className={class_name}
      placeholder={placeholder}
      sx={{ ...stylex }}
      value={selectedValue}
      onChange={handleChange}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.label
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderOption={(props, option) => (
        <li {...props} key={option.id ? option.id : option.val}>
          {option.label}
        </li>
      )}
      ListboxProps={{
        style: {
          maxHeight: 200,
          overflow: "auto",
        },
      }}
    />
  );
};

export default AutocompleteCombobox;
