import { Button, Typography } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { evaluation_saison_service } from "../../../services/PersonalEvaluationService";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import useConfirm from "../../../hooks/useConfirm";
import { evaluation_refonte_saison_service } from "../../../services/PersonalEvaluationRefonteService";

const EvaluationSaisonForm = ({ isOpen, current_saison, handleClose }) => {
  const { showToast, Toast } = useToast();
  const { DialogComponent, openConfirmDialog } = useConfirm();
  const [selected_saison, setSelectedSaison] = useState({
    ID_SAISON: 0,
    SAISON_NAME: "",
    ACTIVE: 0,
    IS_PERSONALIZED: 0,
  });

  const [submitLoad, setLoadSUbmit] = useState(false);

  const handleChangeOuvertStatus = (event) => {
    let misokatra = event.target.checked;
    if (misokatra) {
      openConfirmDialog(
        "Confirmation",
        "Voullez vous ouvrir cette saison? \n Cette action mene à fermer les autres saisons",
        () =>
          setSelectedSaison({
            ...selected_saison,
            ACTIVE: misokatra ? "1" : "0",
          })
      );
    } else {
      setSelectedSaison({
        ...selected_saison,
        ACTIVE: event.target.checked ? "1" : "0",
      });
    }
  };

  useEffect(() => {
    if (current_saison) {
      setSelectedSaison({
        ID_SAISON: current_saison.ID_SAISON,
        SAISON_NAME: current_saison.SAISON_NAME,
        ACTIVE: current_saison.ACTIVE || 0,
        IS_PERSONALIZED: current_saison.IS_PERSONALIZED || 0,
      });
    }
  }, [current_saison]);

  const isAllFieldValide = () => {
    let ok = false;
    if (
      selected_saison.SAISON_NAME === null ||
      selected_saison.SAISON_NAME.trim() === ""
    ) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Vous devez mentionner le nom de la saison"
      );
      ok = false;
    } else if (selected_saison.IS_PERSONALIZED === null) {
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Vous devez mentionner le type de la saison"
      );
      ok = false;
    } else {
      ok = true;
    }

    return ok;
  };

  const handleSaveSaison = () => {
    if (isAllFieldValide()) {
      if (selected_saison.ID_SAISON > 0) {
        handleUpdateSaison();
      } else {
        handleInsertSaison();
      }
    }
  };

  const handleInsertSaison = () => {
    
    evaluation_refonte_saison_service
      .insert(selected_saison)
      .then((res) => {
        if (res && res.success) {
          handleClose();
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          initializeFormFields();
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleUpdateSaison = () => {
    evaluation_refonte_saison_service
      .update(selected_saison)
      .then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          handleClose();
          initializeFormFields();
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleChangeCategoryNouveauGroupe = (evt) => {
    let category = evt.target.value;
    setSelectedSaison({ ...selected_saison, IS_PERSONALIZED: category });
  };

  const handleChangeSelectedSaison = (evt) => {
    const { name, value } = evt.target;
    setSelectedSaison({ ...selected_saison, [name]: value });
  };

  const initializeFormFields = () => {
    setSelectedSaison({
      ID_SAISON: 0,
      SAISON_NAME: "",
      ACTIVE: false,
      IS_PERSONALIZED: "0",
    });
  };

  const handleDialogClose = () => {
    handleClose();
    initializeFormFields();
  };

  return (
    <>
      <Toast />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Paper>
          <Grid
            container
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            overflow={"auto"}
          >
            <DialogComponent />
            <DialogTitle id="dialog-title" className="entete-dialog">
              <Grid display={"flex"} flexDirection={"row"}>
                <Grid alignContent={"center"}>
                  {selected_saison.ID_SAISON === 0
                    ? "Nouvelle saison"
                    : "Modifier saison"}
                </Grid>
                <Grid flexGrow={1}>
                  <DialogActions>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={selected_saison.ACTIVE === "1"}
                          onChange={handleChangeOuvertStatus}
                          name="gilad"
                        />
                      }
                      label={
                        selected_saison.ACTIVE === "1" ? "Ouvert" : "Fermé"
                      }
                    />
                  </DialogActions>
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
              sx={{ maxHeight: "73vh", overflow: "auto" }}
            >
              <Grid container mt={0.5}>
                <Grid item xs={12} className="form_control">
                  <Typography className="form-control">
                    Nom de la saison *
                  </Typography>
                  <TextField
                    fullWidth
                    name="SAISON_NAME"
                    size="small"
                    label="Nom de la saison *"
                    value={selected_saison.SAISON_NAME || ""}
                    onChange={(e) => handleChangeSelectedSaison(e)}
                  />
                </Grid>
                <Grid>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Type du saison
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={selected_saison.IS_PERSONALIZED || "0"}
                      onChange={handleChangeCategoryNouveauGroupe}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Standard"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Personnalisé"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions>
              <LoadingButton
                loading={submitLoad}
                size="small"
                className="form_control"
                type="submit"
                onClick={handleSaveSaison}
                variant="contained"
              >
                Enregistrer{" "}
              </LoadingButton>
              <Button
                className="form_control"
                size="small"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Annuler
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};
export default EvaluationSaisonForm;
