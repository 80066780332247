import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { evaluation_utilities_service } from "../../../services/PersonalEvaluationService";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import AutocompleteCombobox, {
  adaptDataValueToAutocompleteData,
} from "../shared/AutocompleteCombobox";
import {
  evaluation_refonte_fiche_evaluation_service,
  evaluation_refonte_jalon_service,
  evaluation_refonte_questionnaire_par_fiche_service,
} from "../../../services/PersonalEvaluationRefonteService";
import {
  AddOutlined,
  DeleteOutline,
  EditOutlined,
  LinkOutlined,
  RefreshOutlined,
} from "@material-ui/icons";
import CategoryCritereForm from "../category_criteria/CategoryCritereForm";
import CritereForm from "../evaluation_criteria/CritereForm";
import useConfirmation from "../../../hooks/useConfirmation";
import AssignerCritereToFiche from "../evaluation_criteria/AssignerCritereToFiche";
import { ExpandCircleDown } from "@mui/icons-material";

const FicheEvaluationForm = ({ isOpen, ficheEvaluation, handleClose }) => {
  const { showToast, Toast } = useToast();
  const { DialogComponent, openConfirmDialog } = useConfirmation();
  const [submitLoad, setLoadSUbmit] = useState(false);
  const [fonctions, setFonctionsList] = useState([]);
  const [selectedFicheEvaluation, setSelectedFicheEvaluation] = useState({
    ID_FICHE_EVALUATION: 0,
    LIBELE_FICHE: "",
    ID_POSTE: 0,
    ACTIVE: "1",
  });
  const [details, setDetails] = useState([]);
  const [categoryCritereFormOpen, setCategoryCritereFormOpen] = useState(false);
  const [critereFormOpen, setCritereFormOpen] = useState(false);
  const [current_question, setCurrentQuestion] = useState({
    ID_QUESTIONNAIRE: 0,
    ID_FICHE_EVALUATION: 0,
    ID_CATEGORY: 0,
    ID_TYPE_QUESTIONNAIRE: 0,
    NOTE_MIN: 0,
    NOTE_MAX: 0,
    CREATION_DATE: null,
    QUESTION: "",
    IS_COMMON: "0",
  });
  const [selectedCategoryCritere, setCategoryCritere] = useState({
    ID_CATEGORY: 0,
    CATEGORY_NAME: "",
    IS_COMMON: "0",
    ID_POSTE: 0,
    CREATION_DATE: "",
  });
  const [formAssignationOpen, setFormAssignationOpen] = useState(false);
  const [jalonReponses, setJalonReponses] = useState([]);

  const handleCloseAssignationForm = () => {
    handleLoadAllDetails(selectedFicheEvaluation);
    setFormAssignationOpen(false);
  };

  const handleRefreshJalonListByFIcheEvaluation = (id_fiche) => {
    evaluation_refonte_jalon_service.getListByFiche(id_fiche).then((res) => {
      if (res && res.data) {
        setJalonReponses(res.data || []);
      }
    });
  };

  const getInsertableCurrentFicheEvaluation = () => {
    return {
      ID_FICHE_EVALUATION: selectedFicheEvaluation.ID_FICHE_EVALUATION,
      LIBELE_FICHE: selectedFicheEvaluation.LIBELE_FICHE,
      ID_POSTE: selectedFicheEvaluation.ID_POSTE,
      ACTIVE: selectedFicheEvaluation.ACTIVE,
    };
  };

  const handleDialogClose = () => {
    handleClose();
    handleClearAllField();
  };

  const isAllFieldOk = () => {
    let ok = false;
    if (!getInsertableCurrentFicheEvaluation().LIBELE_FICHE) {
      ok = false;
      showToast(
        TYPE_NOTIFICATION.ERROR,
        "Veuillez mentionner le labele du fiche d'evaluation"
      );
    } else {
      if (getInsertableCurrentFicheEvaluation().LIBELE_FICHE.trim() === "") {
        ok = false;
        showToast(
          TYPE_NOTIFICATION.ERROR,
          "Veuillez mentionner le labele du fiche d'evaluation"
        );
      } else {
        ok = true;
      }
    }
    return ok;
  };

  const handleSaveFicheEvaluation = (close = false) => {
    if (isAllFieldOk()) {
      if (getInsertableCurrentFicheEvaluation().ID_FICHE_EVALUATION > 0) {
        handleUpdateCritereGroupee(close);
      } else {
        handleInsertCritereGroupee(close);
      }
    }
  };

  const handleLoadAllDetails = (fiche) => {
    evaluation_refonte_fiche_evaluation_service
      .getAllDetail(fiche.ID_FICHE_EVALUATION)
      .then((res) => {
        if (res) {
          setDetails(res.data);
        }
      });
    handleRefreshJalonListByFIcheEvaluation(fiche.ID_FICHE_EVALUATION);
  };

  const handleInsertCritereGroupee = (close = false) => {
    evaluation_refonte_fiche_evaluation_service
      .insert(getInsertableCurrentFicheEvaluation())
      .then((res) => {
        if (res && res.success) {
          if (close) {
            handleDialogClose();
          } else {
            setSelectedFicheEvaluation(res.data);
            handleLoadAllDetails(res.data);
          }
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };
  const handleUpdateCritereGroupee = (close = false) => {
    evaluation_refonte_fiche_evaluation_service
      .update(getInsertableCurrentFicheEvaluation())
      .then((res) => {
        if (res && res.success) {
          if (close) {
            handleDialogClose();
            handleClearAllField();
          } else {
            console.log("nouveau fiche", res.data);

            setSelectedFicheEvaluation(res.data);
            handleLoadAllDetails(res.data);
            //ouvrir le fenetre nouvelle question
          }
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };

  const handleClearAllField = () => {
    setSelectedFicheEvaluation({
      ID_FICHE_EVALUATION: 0,
      LIBELE_FICHE: "",
      ID_POSTE: 0,
      ACTIVE: "1",
    });
    handleLoadAllDetails(selectedFicheEvaluation);
  };

  const handleChangeCritereGroupeActuel = (evt) => {
    const { name, value } = evt.target;
    setSelectedFicheEvaluation({ ...selectedFicheEvaluation, [name]: value });
  };

  const handleLoadFuncitonList = () => {
    evaluation_utilities_service.getFonctionList(null).then((res) => {
      if (res && res.success) {
        setFonctionsList(res.data);
      }
    });
  };

  const handleChangeActiveStatus = (evt) => {
    const misokatra = evt.target.checked;
    if (misokatra) {
      openConfirmDialog(
        "Confirmation",
        "Voullez vous activer ce fiche d'evaluation? les autres fiches d'evaluatoin de meme poste seront desactivés.",
        () => {
          setSelectedFicheEvaluation({
            ...selectedFicheEvaluation,
            ACTIVE: misokatra ? "1" : "0",
          });
        },
        () => {}
      );
    } else {
      setSelectedFicheEvaluation({
        ...selectedFicheEvaluation,
        ACTIVE: misokatra ? "1" : "0",
      });
    }
  };

  const handleCloseCategoryCritereForm = () => {
    handleLoadAllDetails(selectedFicheEvaluation);
    setCategoryCritereFormOpen(false);
  };
  const handleOpenCategoryCritereForm = (category_critere) => {
    setCategoryCritere(category_critere);
    setCategoryCritereFormOpen(true);
  };

  const handleChangeFonciton = (val) => {
    setSelectedFicheEvaluation({
      ...selectedFicheEvaluation,
      ID_POSTE: val.function_id,
    });
  };

  const handleCritereFormClose = () => {
    handleLoadAllDetails(selectedFicheEvaluation);
    setCritereFormOpen(false);
  };

  const handleCritereFormOpen = (critere) => {
    setCurrentQuestion(critere);
    setCritereFormOpen(true);
  };

  const handleAddNewQuestoinnaire = () => {
    if (getInsertableCurrentFicheEvaluation().ID_FICHE_EVALUATION === 0) {
      openConfirmDialog(
        "Confirmation",
        "Cette fiche d'evalutation n'est pas encore enregistré, voullez vous l'enregistrer d'abord?",
        () => {
          handleSaveFicheEvaluation(false);
        },
        () => {}
      );
    } else {
      handleCritereFormOpen({
        ID_QUESTIONNAIRE: 0,
        ID_FICHE_EVALUATION: 0,
        ID_CATEGORY: 0,
        ID_TYPE_QUESTIONNAIRE: 0,
        NOTE_MIN: 0,
        NOTE_MAX: 0,
        CREATION_DATE: null,
        QUESTION: "",
        IS_COMMON: "0",
      });
    }
  };

  const handleEditQuestionnaire = (questionaire) => {
    setCurrentQuestion(questionaire);
    setCritereFormOpen(true);
  };

  const handleOpenAssignQuestionnaire = () => {
    if (getInsertableCurrentFicheEvaluation().ID_FICHE_EVALUATION === 0) {
      openConfirmDialog(
        "Confirmation",
        "Cette fiche d'evalutation n'est pas encore enregistré, voullez vous l'enregistrer d'abord?",
        () => {
          handleSaveFicheEvaluation(false);
        },
        () => {}
      );
    } else {
      setFormAssignationOpen(true);
    }
  };

  const handleConfirmDeleteQuestionFromFiche = (id_quesition) => {
    evaluation_refonte_questionnaire_par_fiche_service
      .delete(id_quesition)
      .then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          handleLoadAllDetails(selectedFicheEvaluation);
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };

  const handleDeleteQuestionnFromFiche = (id_quesition) => {
    openConfirmDialog(
      "Confirmation",
      "Voullez vous supprimer cette ligne?",
      () => handleConfirmDeleteQuestionFromFiche(id_quesition),
      () => {}
    );
  };

  useEffect(() => {
    //if (ficheEvaluation) {
    handleLoadAllDetails(ficheEvaluation);
    setSelectedFicheEvaluation(ficheEvaluation);

    handleLoadFuncitonList();
    //handleLoadAllDetails(ficheEvaluation || selectedFicheEvaluation);
  }, [ficheEvaluation, isOpen]);

  return (
    <>
      <Toast />
      <DialogComponent />
      <AssignerCritereToFiche
        current_fiche={selectedFicheEvaluation}
        handle_close={handleCloseAssignationForm}
        isOpen={formAssignationOpen}
      />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="md"
      >
        <CritereForm
          current_critere={current_question}
          handleClose={handleCritereFormClose}
          isOpen={critereFormOpen}
        />
        <CategoryCritereForm
          current_category_critere={selectedCategoryCritere}
          isOpen={categoryCritereFormOpen}
          handleClose={handleCloseCategoryCritereForm}
        />
        <Paper>
          <Grid container display={"flex"} flexDirection={"column"}>
            <Grid m={2} display={"flex"} flexDirection={"row"}>
              <Grid alignContent={"center"}>
                <Typography variant="h6">
                  {selectedFicheEvaluation.ID_FICHE_EVALUATION === 0
                    ? "Nouveau fiche d'evaluaiton"
                    : "Modifier fiche d'evaluation"}
                </Typography>
              </Grid>
              <Grid flexGrow={1}>
                <DialogActions>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={selectedFicheEvaluation.ACTIVE === "1"}
                        onChange={(e) => handleChangeActiveStatus(e)}
                        name="gilad"
                      />
                    }
                    label={
                      selectedFicheEvaluation.ACTIVE === "1"
                        ? "Active"
                        : "Inactive"
                    }
                  />
                </DialogActions>
              </Grid>
            </Grid>
            <DialogContent
              id="dialog-description"
              className="app-scroll-bar-view"
            >
              <Grid container flexDirection={"row"} mt={0.5} overflow={"auto"}>
                <Grid className="entete-filtre">
                  <FormControl>
                    <FormLabel>Libelé</FormLabel>
                    <TextField
                      sx={{ mt: 1.25 }}
                      size="small"
                      fullWidth
                      label={"Labele"}
                      name="LIBELE_FICHE"
                      value={selectedFicheEvaluation.LIBELE_FICHE}
                      onChange={(e) => handleChangeCritereGroupeActuel(e)}
                    ></TextField>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Fonction</FormLabel>
                    <AutocompleteCombobox
                      fullWidth
                      stylex={{ width: "300px" }}
                      limitTags={2}
                      label="Fonction"
                      class_name="filtre_list"
                      valeur={adaptDataValueToAutocompleteData(
                        fonctions,
                        "name",
                        "function_id"
                      ).find(
                        (item) =>
                          item.function_id === selectedFicheEvaluation.ID_POSTE
                      )}
                      data={adaptDataValueToAutocompleteData(
                        fonctions,
                        "name",
                        "function_id"
                      )}
                      onChange={handleChangeFonciton}
                    />
                  </FormControl>
                </Grid>
                <Grid className="contenu" width={"100%"}>
                  <Divider variant="fullWidth" />
                  <Grid display={"flex"} flexDirection={"row"}>
                    <Grid flexGrow={1}>
                      <CardHeader
                        title={"Categories et questionnaires"}
                        sx={{ mb: -2 }}
                        titleTypographyProps={{
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      />
                    </Grid>
                    <Grid
                      m={1}
                      className="category"
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                    >
                      <Button
                        variant="text"
                        onClick={(e) =>
                          handleLoadAllDetails(selectedFicheEvaluation)
                        }
                        startIcon={<RefreshOutlined />}
                      ></Button>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={(e) => handleAddNewQuestoinnaire()}
                        startIcon={<AddOutlined />}
                      >
                        Nouvelle question
                      </Button>
                      <Button
                        sx={{ ml: 1 }}
                        size="small"
                        variant="outlined"
                        onClick={(e) => handleOpenAssignQuestionnaire()}
                        startIcon={<LinkOutlined />}
                      >
                        Assigner
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" />
                  <Grid mt={1} maxHeight={"45vh"} overflow={"auto"}>
                    {Array.from(
                      new Set(details.map((dt) => dt.CATEGORY_NAME) || []) || []
                    ).map((category, idx) => (
                      <>
                        <Grid display={"flex"} flexDirection={"row"}>
                          <Grid>
                            <CardHeader
                              title={`Section ${idx + 1}. ${category}`}
                              titleTypographyProps={{
                                variant: "body1",
                                color: "blue",
                                fontSize: "20px",
                              }}
                            />
                          </Grid>
                          <Grid display={"none"} alignItems={"center"}>
                            <Button
                              color="info"
                              size="small"
                              onClick={(e) =>
                                handleOpenCategoryCritereForm(
                                  selectedCategoryCritere
                                )
                              }
                            >
                              <EditOutlined />
                            </Button>
                            <Button color="warning" size="small">
                              <DeleteOutline />
                            </Button>
                          </Grid>
                        </Grid>
                        {details
                          .filter((dt) => dt.CATEGORY_NAME === category)
                          .map((detail, dt_idx) => (
                            <Accordion key={dt_idx}>
                              <AccordionSummary
                                expandIcon={
                                  String(detail.TYPE_NAME)
                                    .toLowerCase()
                                    .includes("jalon") ? (
                                    <ExpandCircleDown />
                                  ) : (
                                    ""
                                  )
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Grid
                                  display={"flex"}
                                  width={"100%"}
                                  flexDirection={"row"}
                                >
                                  <Grid flexGrow={1}>
                                    <Typography>
                                      {`${dt_idx + 1}. ` + detail.QUESTION}
                                    </Typography>
                                    <Grid
                                      display={"flex"}
                                      flexDirection={"row"}
                                      justifyContent={"space-between"}
                                      mt={1.5}
                                    >
                                      <Grid
                                        textAlign={"center"}
                                        display={"flex"}
                                        flexDirection={"row"}
                                      >
                                        <Typography variant="caption">
                                          Type :
                                          <span>
                                            {detail.TYPE_NAME}
                                            {String(detail.TYPE_NAME)
                                              .toLowerCase()
                                              .includes("note")
                                              ? ` (de ${detail.NOTE_MIN} à ${detail.NOTE_MAX})`
                                              : ``}
                                          </span>
                                        </Typography>
                                      </Grid>
                                      <Divider orientation="vertical" />
                                      <Grid
                                        textAlign={"center"}
                                        display={"flex"}
                                        flexDirection={"row"}
                                      >
                                        <Typography variant="caption">
                                          Crée le :{" "}
                                          <span>{detail.CREATION_DATE}</span>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid display={"flex"} flexDirection={"row"}>
                                    <Tooltip title="Cliquer pour modifier">
                                      <Button
                                        color="info"
                                        size="small"
                                        onClick={(e) =>
                                          handleEditQuestionnaire({
                                            ID_QUESTIONNAIRE:
                                              detail.ID_QUESTIONNAIRE,
                                            ID_FICHE_EVALUATION:
                                              detail.ID_FICHE_EVALUATION,
                                            ID_CATEGORY: detail.ID_CATEGORY,
                                            ID_TYPE_QUESTIONNAIRE:
                                              detail.ID_TYPE_QUESTIONNAIRE,
                                            NOTE_MIN: detail.NOTE_MIN,
                                            NOTE_MAX: detail.NOTE_MAX,
                                            QUESTION: detail.QUESTION,
                                          })
                                        }
                                      >
                                        <EditOutlined />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Cliquer pour supprimer">
                                      <Button
                                        color="warning"
                                        size="small"
                                        onClick={(e) =>
                                          handleDeleteQuestionnFromFiche(
                                            detail.ID_QUESTIONNAIRE
                                          )
                                        }
                                      >
                                        <DeleteOutline />
                                      </Button>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails
                                sx={{
                                  display: String(detail.TYPE_NAME).includes(
                                    "jalon"
                                  )
                                    ? ""
                                    : "none",
                                }}
                              >
                                <Divider variant="middle" />
                                <Grid >
                                  <CardHeader
                                    title={"Liste des reponses jalon"}
                                    sx={{ mb: -2 }}
                                    titleTypographyProps={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  />
                                  <Grid container m={0.5}>
                                    {jalonReponses
                                      .filter(
                                        (item) =>
                                          item.ID_QUESTIONNAIRE ===
                                          detail.ID_QUESTIONNAIRE
                                      )
                                      .map((item, idx) => (
                                        <>
                                          <Paper
                                            key={idx}
                                            elevation={2}
                                            sx={{ m: 0.5 }}
                                          >
                                            <Grid
                                              key={idx}
                                              p={1}
                                              display={"flex"}
                                              flexDirection={"row"}
                                            >
                                              <Grid
                                                flexGrow={1}
                                                display={"flex"}
                                                alignContent={"center"}
                                              >
                                                {`${idx + 1}. ${item.VALEUR}`}
                                              </Grid>
                                            </Grid>
                                          </Paper>
                                        </>
                                      ))}
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          ))}

                        <Grid
                          m={1}
                          className="category"
                          display={"none"}
                          justifyContent={"flex-end"}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                            startIcon={<AddOutlined />}
                          >
                            Ajouter question
                          </Button>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions>
              <LoadingButton
                loading={submitLoad}
                size="small"
                className="form_control"
                type="submit"
                onClick={(e) => handleSaveFicheEvaluation(true)}
                variant="contained"
              >
                Enregistrer{" "}
              </LoadingButton>
              <Button
                className="form_control"
                size="small"
                onClick={handleDialogClose}
                variant="outlined"
              >
                Fermer
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default FicheEvaluationForm;
