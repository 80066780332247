import React, { useState } from "react";
import { headerPersonnalEvalutaion } from "../../data/modules/personnal-evaluation-header";
import MainContainer from "../../components/MainContainer";
import AppPageForTableList from "../personal_evaluation/shared/AppPageForTableList";
import ValidatorsFiltre from "./ValidatorsFiltre";

const ValidatorList = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  };
  const handleCloseDialog = () => {
    setOpenForm(false);
  };
  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppPageForTableList
        title={"Mes validators"}
        button_creer_title={"Gerer"}
        handleClickBtnCreer={handleOpenDialog}
        filter_component={
          <ValidatorsFiltre
            handleCloseFormDialog={handleCloseDialog}
            handleOpenFormDialog={handleOpenDialog}
            openForm={openForm}
          />
        }
      />
    </MainContainer>
  );
};

export default ValidatorList;
