import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const PersonnelService = {

  async savePerson(first_name, last_name, personnal_email, card_number, card_date, card_place, card_duplicate, birth_date, birth_place,
    telephone, sex, address, marital_status, number_children, registration_number, office_number, phone_number, urgence, name_image, nif, stat
    , service, fonction, end_time, porte, siege, batiment, superieur, group) {
    try {
      const result = await axios.post(`${apiUrl}/personnal/save`, {
        "first_name": first_name,
        "last_name": last_name,
        "personnal_email": personnal_email,
        "card_number": card_number,
        "card_date": card_date,
        "card_place": card_place,
        "card_duplicate": card_duplicate,
        "birth_date": birth_date,
        "birth_place": birth_place,
        "telephone": telephone,
        "sex_id": sex,
        "address": address,
        "marital_status": marital_status,
        "number_children": number_children,
        "personnal_email": personnal_email,
        "registration_number": registration_number,
        "office_number": office_number,
        "phone_number": phone_number,
        "urgence": urgence,
        "name_image": name_image,
        "nif": nif,
        "stat": stat,
        "service_id": service,
        "function_id": fonction,
        "end_time": end_time,
        "porte": porte,
        "siege": siege,
        "batiment": batiment,
        "superieur": superieur,
        "groups": group,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveUser(personnal_email, registration_number, office_number, phone_number, urgence, name_image, nif, stat) {
    try {
      const result = await axios.post(`${apiUrl}/personnal/user`, {
        "personnal_email": personnal_email,
        "registration_number": registration_number,
        "office_number": office_number,
        "phone_number": phone_number,
        "urgence": urgence,
        "name_image": name_image,
        "nif": nif,
        "stat": stat,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveGroup(group) {
    try {
      const result = await axios.post(`${apiUrl}/personnal/usergroup`, {
        "group_id": group,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveTfunction(fonction, end_time, porte, siege, batiment) {
    try {
      const result = await axios.post(`${apiUrl}/personnal/tfunction`, {
        "function_id": fonction,
        "end_time": end_time,
        "porte": porte,
        "siege": siege,
        "batiment": batiment,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async saveTservice(service) {
    try {
      const result = await axios.post(`${apiUrl}/personnal/tservice`, {
        "service_id": service,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveUSer(registration_number, office_number, phone_number, urgence, id) {
    try {
      const result = id ?
        await axios.put(`${apiUrl}/personnal/${id}`, {
          id: id,
          "registration_number": registration_number,
        })
        :
        await axios.put(`${apiUrl}/personnal/user`, {
          "registration_number": registration_number,
          "office_number": office_number,
          "phone_number": phone_number,
          "urgence": urgence,
        });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async savePersonAdd(ddn, lieu, cin, type, date_cin, adresse, nombre_enfant, lieu_cin, datep_cin, id) {
    try {
      const result = id ?
        await axios.put(`${apiUrl}/personnal/${id}`, {
          id: id,
        })
        :
        await axios.put(`${apiUrl}/personnal/person`, {
          "card_number": cin,
          "card_date": date_cin,
          "card_place_number": cin,
          "cardp_date": date_cin,
          "birth_date": ddn,
          "birth_place": lieu,
          "address": adresse,
          "marital_status": type,
          "number_children": nombre_enfant
        });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getGroup() {
    try {
      const result = await axios.get(`${apiUrl}/personnal/group`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getUpdate(id) {
    try {
      const result = await axios.get(`${apiUrl}/personnal/person/${id}`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getDirection() {
    try {
      const result = await axios.get(`${apiUrl}/personnal/direction`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getFunction() {
    try {
      const result = await axios.get(`${apiUrl}/personnal/fonction`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getAntenne() {
    try {
      const result = await axios.get(`${apiUrl}/personnal/antenne`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getServices() {
    try {
      const result = await axios.get(`${apiUrl}/personnal/services`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getList() {
    try {
      const result = await axios.get(`${apiUrl}/personnal/list`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getListe(id) {
    try {
      const result = await axios.get(`${apiUrl}/personnal/liste/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getperson(id) {
    try {
      const result = await axios.get(`${apiUrl}/personnal/person/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async isActiveUser(id) {
    try {
      const result = await axios.put(`${apiUrl}/personnal/useractive/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveAll(first_name, last_name, personnal_email, card_number, card_date, card_place, card_duplicate, birth_date, birth_place,
    telephone, sex, address, marital_status, number_children, registration_number, office_number, phone_number, urgence, name_image, nif, stat
    , service, fonction, porte, siege, batiment, superieur, group, id) {
    try {
      const result = await axios.put(`${apiUrl}/personnal/all/${id}`, {
        id: id,
        "first_name": first_name,
        "last_name": last_name,
        "personnal_email": personnal_email,
        "cin": card_number,
        "date_cin": card_date,
        "datep_cin": card_place,
        "lieu_cin": card_duplicate,
        "ddn": birth_date,
        "lieu": birth_place,
        "urgence": telephone,
        "sex_id": sex,
        "adresse": address,
        "type": marital_status,
        "nombre_enfant": number_children,
        "gps": personnal_email,
        "matricule": registration_number,
        "flotte": office_number,
        "bureau": phone_number,
        "urgence": urgence,
        "nif": nif,
        "stat": stat,
        "name_image": name_image,
        "batiment": batiment,
        "fonction": fonction,
        "porte": porte,
        "siege": siege,
        "service": service,
        "superieur": superieur,
        "groups": group
      })
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async importService(formData, name) {
    try {
      const result = await axios.post(`${apiUrl}/personnal/import`, formData);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async importphotos(formData) {
    try {
      const result = await axios.post(`${apiUrl}/personnal/importphotos`, formData);
      if (result) {
        return result.data.file_name;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getuserSuperior(id) {
    try {
      const result = await axios.get(`${apiUrl}/personnal/usersuperior/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getcin(id) {
    try {
      const result = await axios.get(`${apiUrl}/personnal/recherchecin/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getMaxUserId() {
    try {
      const result = await axios.get(`${apiUrl}/personnal/maxUserId`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },
  async findAllPersonnal(id_dir, id_service) {
    try {
      const result = await axios.get(`${apiUrl}/Personnal/findAllPersonnal/${id_dir}/${id_service}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  //export fichier vcf for multiple contact
  async exportVcf(data, service_id, direction_id) {
    try {
      const result = await axios.post(`${apiUrl}/personnal/exportVcf`, {
        data : data,
        service_id : service_id,
        direction_id : direction_id
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  //export fichier vcf for single contact
  async exportSingleVcf(data) {
    try {
      const result = await axios.post(`${apiUrl}/personnal/exportSingleVcf`, {
        data : data
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getPerson(function_id) {
    try {
        const result = await axios.get(`${apiUrl}/person/getPersonByFunctionID/${function_id}`);
        if (result) {
            return result.data;
        }
    }
    catch (error) {
        if (error.response) {
            return error.response.data;
        }
    }
},
async generateQrPdf(iddir,idservice) {
  try {
    const result = await axios.get(`${apiUrl}/AnnuaireService/generateAllQr?direction_id=${iddir}&service_id=${idservice}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
      }});
    if (result) {
      // TODO: replace result.data.message by the token
      return result;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
},
async generateSingleQrPdf(user_id) {
  try {
    const result = await axios.get(`${apiUrl}/AnnuaireService/generateQR`, {
      params: {
        user_id: user_id,
      },
      responseType: 'blob', // Set the responseType to 'blob' for binary data like PDF
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    if (result) {
      return result;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
},
    async generateBadgePdf(user_id) {//generateBadge for single user
      try {
        const result = await axios.get(`${apiUrl}/AnnuaireService/generateBadge`, {
          params: {
            user_id: user_id,
          },
          responseType: 'blob', 
          headers: {
            'Content-Type': 'application/pdf',
          },
        });

        if (result) {
          return result;
        }
      } catch (error) {
        if (error.response) {
          return error.response.data;
        }
      }
    },

  async getBagdeUsers(idsUers) { //generateBadge for multiple users
    try {
      const result = await axios.post(`${apiUrl}/AnnuaireService/DataToBagdeUsers/`, 
        {
          idsUers: idsUers,
        }, 
        {
          responseType: 'blob', 
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async generateQRSelectedUsers(idsUers) { //generateQR for multiple users
    try {
      const result = await axios.post(`${apiUrl}/AnnuaireService/generateUsersQr/`, 
        {
          idsUers: idsUers,
        }, 
        {
          responseType: 'blob', 
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
}
export default PersonnelService;