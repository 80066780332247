import { useState } from "react";
import MainContainer from "../../../components/MainContainer";
import { headerPersonnalEvalutaion } from "../../../data/modules/personnal-evaluation-header";
import AppPageForTableList from "../shared/AppPageForTableList";
import FicheEvaluationListFiltre from "./FicheEvaluationListFiltre";

const FicheEvaluationList = () => {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenDialog = () => {
    setOpenForm(!openForm);
  };
  const handleCloseDialog = () => {
    setOpenForm(false);
  };
  return (
    <MainContainer {...headerPersonnalEvalutaion}>
      <AppPageForTableList
        title={"Liste des fiches d'evaluations"}
        handleClickBtnCreer={handleOpenDialog}
        hide_buton_creer={true}
        filter_component={
          <FicheEvaluationListFiltre
            openForm={openForm}
            handleCloseFormDialog={handleCloseDialog}
            handleOpenFormDialog={handleOpenDialog}
          />
        }
      />
    </MainContainer>
  );
};

export default FicheEvaluationList;
