import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { RefreshOutlined } from "@material-ui/icons";
import { DownloadOutlined } from "@mui/icons-material";
import EmployeeListTable from "./EmployeeListTable";
import { Paper } from "@material-ui/core";
import AutocompleteCombobox, {
  adaptDataValueToAutocompleteData,
} from "../shared/AutocompleteCombobox";
import PlanningMissionService from "../../../services/PlanningMissionService";
import {
  evaluation_refonte_saison_service,
  evaluation_utilitises_service,
} from "../../../services/PersonalEvaluationRefonteService";
import { evaluation_utilities_service } from "../../../services/PersonalEvaluationService";

const EmployeeListFiltre = ({ title }) => {
  const [filtre, setFiltre] = useState({
    critere: null,
    direction: {},
    service: {},
    session: {},
    evalued: false,
    mot_cle: "",
    refresh: 0,
  });
  const [openForm, setOpenForm] = useState(false);
  const [excelExport, declenceExportToExcel] = useState(0);
  const [directions, setDirections] = useState([]);
  const [services, setServices] = useState([]);
  const [sessions, setSessions] = useState([]);

  const downloadExcel = () => {
    declenceExportToExcel(excelExport + 1);
  };

  const handleOpenDialogForm = () => {};

  const handleCloseDialogForm = () => {};

  const handlechangeFiltre = (evt) => {
    const { name, value } = evt.target;
    setFiltre({ ...filtre, [name]: value });
  };

  const handleRefreshTable = () => {
    setFiltre({ ...filtre, refresh: filtre.refresh + 1 });
  };
  const handleChangeAutoCompleteDirectionFilter = (new_val) => {
    setFiltre({ ...filtre, direction: new_val });
    handleFetchServicesList(new_val);
  };
  const handleChangeAutoCompleteServiceFilter = (new_val) => {
    setFiltre({ ...filtre, service: new_val });
  };
  const handleChangeAutoCompleteSaisonFilter = (new_val) => {
    setFiltre({ ...filtre, session: new_val });
  };

  const handleFetchDirectionList = () => {
    PlanningMissionService.getDirection().then((res) => {
      if (res && res.data) {
        setDirections(res.data);
      }
    });
  };
  const handleFetchServicesList = (direction) => {
    if (direction && direction.direction_id) {
      evaluation_utilitises_service
        .getServiceList(direction.direction_id)
        .then((res) => {
          if (res && res.data) {
            setServices(res.data);
          }
        });
    }
  };
  const handleFetchSaisonList = () => {
    evaluation_refonte_saison_service.getList({ mot_cle: "" }).then((res) => {
      if (res) setSessions(res.data);
    });
  };

  useEffect(() => {
    handleFetchDirectionList();
    handleFetchSaisonList();
  }, []);

  return (
    <Paper>
      <Grid p={2} display={"flex"} flexDirection={"column"} height={"80vh"}>
        <Typography mb={1} color={"#1a73e8"} textAlign={"center"} variant="h6">
          {title}
        </Typography>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <AutocompleteCombobox
            fullWidth
            stylex={{ width: "120px" }}
            limitTags={2}
            label="Direction"
            class_name="filtre_list"
            data={adaptDataValueToAutocompleteData(
              directions,
              "code",
              "direction_id"
            )}
            onChange={handleChangeAutoCompleteDirectionFilter}
          />
          <AutocompleteCombobox
            fullWidth
            stylex={{ width: "150px" }}
            limitTags={2}
            label="Service"
            class_name="filtre_list"
            data={adaptDataValueToAutocompleteData(
              services,
              "code",
              "service_id"
            )}
            onChange={handleChangeAutoCompleteServiceFilter}
          />
          <AutocompleteCombobox
            fullWidth
            stylex={{
              width: "200px",
              display:
                title === "Liste de toutes mes subordonnées" ? "none" : "",
            }}
            limitTags={2}
            label="Session"
            class_name="filtre_list"
            data={adaptDataValueToAutocompleteData(
              sessions,
              "SAISON_NAME",
              "ID_SAISON"
            )}
            onChange={handleChangeAutoCompleteSaisonFilter}
          />
          <TextField
            sx={{ width: 250 }}
            size="small"
            value={filtre.mot_cle}
            name="mot_cle"
            label="Mot clé"
            onChange={handlechangeFiltre}
            className="filtre_list"
          />
          <Button
            variant="outlined"
            className="filtre_list"
            onClick={() => handleRefreshTable()}
          >
            <RefreshOutlined />
          </Button>
          <Button
            variant="outlined"
            className="filtre_list"
            onClick={downloadExcel}
          >
            <DownloadOutlined /> Excel export
          </Button>
        </Grid>
        <Grid flexGrow={1} mt={3}>
          <EmployeeListTable
            filtre={filtre}
            excelExport={excelExport}
            handleOpenDialog={handleOpenDialogForm}
            onCloseForm={handleCloseDialogForm}
            openForm={openForm}
            title={title}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default EmployeeListFiltre;
