import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { Grid } from "@mui/material";

function calculateValue(value) {
  return value;
}

const SliderNotation = ({
  val,
  step,
  min_val,
  max_val,
  on_leave,
  disabled = false,
}) => {
  const [value, setValue] = React.useState(val || 0);
  const [max_value, setMaxValue] = React.useState(max_val || 10);
  const [min_value, setMinValue] = React.useState(min_val || 0);
  const [step_value, setStepValue] = React.useState(step || 1);

  function valueLabelFormat(value) {
    return `${value} / ${max_value}`;
  }

  const getTicksMarks = (min, max, pas) => {
    let columns = [];
    for (let i = min-1; i < max; i += pas) {
      columns.push({
        value: i + 1,
        label: i + 1,
      });
    }
    return columns;
  };

  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
  };

  const handleMouseOnLeave = (evt, newVal) => {
    if (on_leave) {
      on_leave(newVal);
    }
  };

  const findOutEvaluationGenerale = (note_total, note_max_total) => {
    /*  let note_total = questions.reduce(
        (somme, qs) => somme + Number(qs.NOTE),
        0
      ),
      note_max_total = questions.reduce(
        (somme, qs) => somme + Number(qs.NOTE_MAX),
        0
      ), */
    let moyenne_generale = note_total / note_max_total;

    if (moyenne_generale < 11 / 50) {
      return "Faible";
      //return { couleur: "#a00", valeur: "Faible" };
    } else if (moyenne_generale > 10 / 50 && moyenne_generale < 21 / 50) {
      return "A améliorer";
      //return { couleur: "#a50", valeur: "A améliorer" };
    } else if (moyenne_generale > 20 / 50 && moyenne_generale < 31 / 50) {
      return "Satisfaisant";
      //return { couleur: "#aa0", valeur: "Satisfaisant" };
    } else if (moyenne_generale > 30 / 50 && moyenne_generale < 41 / 50) {
      return "Superieur";
      //return { couleur: "#00a", valeur: "Superieur" };
    } else if (moyenne_generale > 40 / 50) {
      return "Exceptionnel";
      //return { couleur: "#080", valeur: "Exceptionnel" };
    }
  };

  return (
    <Grid justifyContent="center" width={"50vw"}>
      <Typography id="non-linear-slider" gutterBottom>
        Note donnée:{" "}
        {`${valueLabelFormat(value, max_value)}  (${findOutEvaluationGenerale(
          value,
          max_value
        )})`}
      </Typography>
      <Slider
        value={value}
        disabled={disabled}
        min={min_value}
        step={step_value}
        marks={getTicksMarks(min_value, max_value, step_value)}
        max={max_value}
        scale={calculateValue}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
        onMouseLeave={(e) => handleMouseOnLeave(e, value)}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
      />
    </Grid>
  );
};

export default SliderNotation;
