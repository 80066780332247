import { Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import {
  DeleteOutline,
  EditOutlined,
  ListAltSharp,
  RemoveRedEyeOutlined,
} from "@material-ui/icons";
import { useNavigate } from "react-router";
import FicheEvaluationForm from "./FicheEvaluationForm";
import useConfirm from "../../../hooks/useConfirm";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { exportToExcel } from "../../../helpers/ExportHelpers";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import FicheDevaluation from "./FicheDevaluation";
import { evaluation_refonte_fiche_evaluation_service } from "../../../services/PersonalEvaluationRefonteService";

const FicheEvaluationTable = ({
  openForm,
  onCloseForm,
  excelExport,
  filtre,
}) => {
  const [criteres, setCriteres] = useState([]);
  const navigate = useNavigate();
  const [current_critere, setCurrentCritere] = useState({
    ID_FICHE_EVALUATION: 0,
    LIBELE_FICHE: "",
    ID_POSTE: 0,
    ACTIVE: "1",
  });
  const [form_open, setFormOpen] = useState(openForm);
  const [fiche_evaluation_form_open, setFicheEvaluationFormOpen] =
    useState(false);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);
  const { DialogComponent, openConfirmDialog } = useConfirm();
  const { Toast, showToast } = useToast();

  const handleCloseFormDIalog = () => {
    onCloseForm();
    handleRefreshDataTable(filtre);
    setFormOpen(false);
  };

  const handleEditSelectedCritereGroupee = (critereGroupee) => {
    setCurrentCritere(critereGroupee);
    setFormOpen(true);
  };

  const handleConfirmSuppresion = (critere) => {
    evaluation_refonte_fiche_evaluation_service.delete(critere).then((res) => {
      if (res && res.success) {
        showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
        handleRefreshDataTable(filtre);
      } else {
        showToast(TYPE_NOTIFICATION.ERROR, res.message);
      }
    });
  };

  const handleDeleteSelectedCritereGroupee = (evt, critereGroupee) => {
    evt.preventDefault();
    openConfirmDialog(
      "Confirmation",
      "Voulez vous supprimer cette ligne?",
      () => handleConfirmSuppresion(critereGroupee)
    );
  };

  const handleShowSelectedCritereGroupee = (ficheEvaluation) => {
    navigate(
      `/critere_groupee_detail?id_groupe=${ficheEvaluation.ID_FICHE_EVALUATION}`
    );
  };
  const handleShowSelectedCritereGroupeeByPosition = (critereGroupee) => {
    setCurrentCritere(critereGroupee);
    setFicheEvaluationFormOpen(true);
  };

  const handleCLoseFicheDePoste = () => {
    setFicheEvaluationFormOpen(false);
  };

  const handleRefreshDataTable = (filtre) => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_refonte_fiche_evaluation_service
      .getList(filtre)
      .then((res) => {
        setOpenLoadingSimpleBackdrop(false);
        setCriteres(res.data || []);
      })
      .catch((e) => console.log(e.toString()));
  };

  useEffect(() => {

    if (filtre) {
      handleRefreshDataTable(filtre);
    }
    if (openForm) {
      setFormOpen(openForm);
    }
    if (excelExport > 0) {
      exportToExcel(criteres, columns, "Groupe de critere liste " + new Date());
    }
  }, [filtre, openForm, excelExport,current_critere]);

  const columns = [
    {
      field: "LIBELE_FICHE",
      headerName: "Libelé",
      width: 325,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: "Fonction",
      width: 380,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "ACTIVE",
      headerName: "Active ",
      width: 120,
      renderCell: (params) => (
        <Tooltip title={params.value === "1" ? "Oui" : "non"}>
          <Typography>{params.value === "1" ? "Oui" : "non"}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "CREATION_DATE",
      headerName: "Date de la création ",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "libelle_actions",
      headerName: "Actions",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            {/* <Tooltip
              title={"Cliquer pour afficher l'appercu fiche d'evaluation"}
            >
              <LoadingButton
                onClick={(e) =>
                  handleShowSelectedCritereGroupeeByPosition(params.row)
                }
                color="primary"
              >
                <ListAltSharp />
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour afficher "}>
              <LoadingButton
                onClick={(e) => handleShowSelectedCritereGroupee(params.row)}
                color="primary"
              >
                <RemoveRedEyeOutlined />
              </LoadingButton>
            </Tooltip> */}
            <Tooltip title={"Cliquer pour Modifier "}>
              <LoadingButton
                onClick={(e) => handleEditSelectedCritereGroupee(params.row)}
                color="secondary"
              >
                <EditOutlined />
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour Supprimer "}>
              <LoadingButton
              sx={{display : "none"}}
                onClick={(e) =>
                  handleDeleteSelectedCritereGroupee(e, params.row)
                }
                color="warning"
              >
                <DeleteOutline />
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <Toast />
      <DialogComponent />
      <FicheDevaluation
        current_critere_groupe={current_critere}
        isOpen={fiche_evaluation_form_open}
        handleClose={handleCLoseFicheDePoste}
      />
      <FicheEvaluationForm
        ficheEvaluation={current_critere}
        handleClose={handleCloseFormDIalog}
        isOpen={form_open}
      />
      <DataGrid
        rows={criteres}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
        getRowId={(row) => row.ID_FICHE_EVALUATION}
      />
    </>
  );
};
export default FicheEvaluationTable;
