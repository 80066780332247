import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Button ,TextField,FormControl, Select,MenuItem} from "@material-ui/core";
import Spacer from "../../../components/Spacer";
import MainContainer from "../../../components/MainContainer";
import { headerDataRH } from "../../../data/modules/hr-header";
import { useNavigate } from 'react-router-dom';
import TimeTrackingService from "../../../services/TimeTrackingService";
import Title from "../../../components/Title";
import { Gif } from "@material-ui/icons";
import Message from "../../tools/Message";
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        elevation: 0,
    },
}));

export default function TimeTrackingAdd(props) {

    const navigate = useNavigate();
    const params = useParams();
    const idtime=params.id;
    console.log("id",idtime);
    
    const [form, setForm] = useState({
        id:'',
        date: '',
        observation: ''
    });
    const [isvalidate,setIsvalidate]=useState(true);
      const [message, setMessage] = useState({
        open: false,
        color: 'green',
        messageInfo: '',
      });
      const { open, color, messageInfo } = message;

      const handleChange = (e) => {
            const { name, value } = e.target;
 
            const dateAujourdui = new Date(); 
            const dateValue = new Date(value);
        
            if (name === "date" && dateValue > dateAujourdui) {
                setMessage({
                    ...message,
                    open: true,
                    color: 'red',
                    messageInfo: "La date doit être inférieure ou égale à aujourd'hui"
                });
                setIsvalidate(true);
            } else {
                setIsvalidate(false);
                setForm(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        };
    
    
      const handleClick = () => {
        if (form.date==='' || form.observation==='') {
            setMessage({...message, open: true, color: 'red', messageInfo:"Veuillez saisir les informations requises dans ce champ."});
        }
        else{
            if (form.id!=='') {
                
                TimeTrackingService.updateTimePrevisionaly(form.id,form.date,form.observation).then((results) => {
                    if (results) {
                        setMessage({...message, open: true, color: 'green', messageInfo: results.message});
                        navigate('/timeTrackingprovisional');
                    }
                    else{
                        setMessage({...message, open: true, color: 'red', messageInfo: results.message});
                    }
                });
            } else {
                TimeTrackingService.setTimePrevisionaly(form.date,form.observation).then((results) => {
                    if (results) {
                        setMessage({...message, open: true, color: 'green', messageInfo: results.message});
                        navigate('/timeTrackingprovisional');
                    }
                    else{
                        setMessage({...message, open: true, color: 'red', messageInfo: results.message});
                    }
                });
          }
        }
        
    };

    function handleCloseMessage(){
      setMessage({...message, open: false});
    }
    
    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];  
    }

    useEffect(()=>{
        if (idtime!==undefined) {
            TimeTrackingService.getByIDTimePrevisionaly(idtime).then((results) => {
                if (results) {
                    setIsvalidate(false);
                    setForm(prevState => ({
                        ...prevState,
                         id:results.data.timetraking_id,
                         date:formatDate(results.data.timetraking_date),
                         observation:results.data.observation
                    }));
                } 
            });
        }
    },[]);

    return (
        <MainContainer {...headerDataRH}>
          <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
          <Paper>
            <Grid container spacing={3}>
                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <label>Date * :</label>
                </Grid>
                <Grid item xs={8}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="date"
                     onChange={handleChange}
                     value={form.date}
                    type="date"
                />
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                 <label>Motif * :</label>
                </Grid>
                <Grid item xs={8}>
                <TextField
                    fullWidth
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    name="observation"
                    value={form.observation}
                    onChange={handleChange}
                />
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                     <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClick}
                        style={{ fontWeight: 600 }}
                        disabled={isvalidate}
                    >
                        ENREGISTRER 
                    </Button>
                </Grid>
            </Grid>
            </Paper>
        </MainContainer>
    );
}
