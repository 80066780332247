import {
  Alert,
  AlertTitle,
  Button,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useToast from "../../../hooks/useToast";
import useConfirm from "../../../hooks/useConfirm";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import UserService from "../../../services/UserService";
import {
  evaluation_refonte_fiche_evaluation_service,
  evaluation_refonte_jalon_service,
  evaluation_refonte_reponse_service,
  evaluation_refonte_saison_service,
} from "../../../services/PersonalEvaluationRefonteService";
import SliderNotation from "./comps/SliderNotation";
import { findOutEvaluationGenerale } from "./EvaluationExaminationFormResume";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const TYPE_REPONSE = {
  comment: { id: 0, name: "Commentaire" },
  texte_libre: { id: 1, name: "Reponse libre" },
  note: { id: 2, name: "En note" },
  jalon: { id: 3, name: "En jalon" },
};

const EvaluationExaminationForm = ({ isOpen, candidat, handleClose }) => {
  const { Toast, showToast } = useToast();
  const { DialogComponent, openConfirmDialog } = useConfirm();
  const [openLoadingSimpleBackdrop, setopenLoadingSimpleBackdrop] =
    useState(false);
  const [evaluateur, setEvaluateur] = useState({});
  const [active_saison, setActiveSaison] = useState({});
  const [selectedCandidat, setSelectedCandidat] = useState(
    candidat || {
      last_name: "",
      first_name: "",
      function_name: "",
      direction_code: "",
      service_code: "",
      sup_f_name: "",
      sup_fonction: "",
      sup_l_name: "",
    }
  );
  const [detailsFicheEvaluation, setDetailsFicheEvaluation] = useState([]);
  const [jalons, setJalons] = useState([]);

  const getInsertableDetailEvaluation = (cloturer = false) => {
    let res = detailsFicheEvaluation.slice();
    return res.map((item, idx) => {
      let reponse = {
        ID_REPONSE: item.ID_REPONSE,
        ID_SAISON: item.ID_SAISON || active_saison.ID_SAISON,
        ID_QUESTIONNAIRE: item.ID_QUESTIONNAIRE,
        ID_TYPE_REPONSE: item.ID_TYPE_REPONSE,
        ID_CANDIDAT: selectedCandidat.user_id,
        TERMINEE: cloturer ? "1" : item.TERMINEE,
        NOTE: item.NOTE || item.NOTE_CA,
        REPONSE: item.REPONSE || item.REP_CA,
        COMMENTAIRE_SUP: item.COMMENTAIRE_SUP || item.COMM_CA,
      };
      if (String(item.TYPE_NAME).includes("note") && item.NOTE === null) {
        return { ...reponse, NOTE: item.NOTE_CA || item.NOTE_MIN };
      } else {
        return reponse;
      }
    });
  };

  const handleFechJalonList = (id_fiche) => {
    //setopenLoadingSimpleBackdrop(true);
    evaluation_refonte_jalon_service
      .getListByFiche(id_fiche)
      .then((res) => {
        if (res) {
          setJalons(res.data || []);
        }
      })
      .finally(() => {
        //setopenLoadingSimpleBackdrop(false);
      });
  };
  const handleFechEvaluateur = () => {
    //setopenLoadingSimpleBackdrop(true);
    UserService.myCompleteUserInfoForEvaluation()
      .then((res) => {
        if (res) {
          setEvaluateur(res.data);
        }
      })
      .finally(() => {
        //setopenLoadingSimpleBackdrop(false);
      });
  };
  const handleFechFicheEvaluation = () => {
    setDetailsFicheEvaluation([]);
    setopenLoadingSimpleBackdrop(true);
    evaluation_refonte_fiche_evaluation_service
      .getAllDetailByUserId(candidat ? candidat.user_id : 0)
      .then((res) => {
        if (res) {
          setDetailsFicheEvaluation(res.data);
          if (res.data.length > 0) {
            handleFechJalonList(res.data[0].ID_FICHE_EVALUATION);
          }
        }
      })
      .finally(() => {
        setopenLoadingSimpleBackdrop(false);
      });
  };
  const handleFechFicheActiveSaison = () => {
    //setopenLoadingSimpleBackdrop(true);
    evaluation_refonte_saison_service
      .getActiveOne()
      .then((res) => {
        if (res) {
          setActiveSaison(res.data);
        }
      })
      .finally(() => {
        // setopenLoadingSimpleBackdrop(false);
      });
  };

  const handleUpdateEvaluationReponse = (obj, new_note_val, type_value) => {
    let idx_pricipal = detailsFicheEvaluation.indexOf(obj);
    let copy_questions = detailsFicheEvaluation.slice();
    if (type_value === TYPE_REPONSE.note) {
      copy_questions[idx_pricipal].NOTE = new_note_val;
    } else if (type_value === TYPE_REPONSE.texte_libre) {
      copy_questions[idx_pricipal].REPONSE = new_note_val;
    } else if (type_value === TYPE_REPONSE.jalon) {
      copy_questions[idx_pricipal].REPONSE = new_note_val;
    } else if (type_value === TYPE_REPONSE.comment) {
      copy_questions[idx_pricipal].COMMENTAIRE_SUP = new_note_val;
    } else {
      copy_questions[idx_pricipal].COMMENTAIRE_SUP = new_note_val;
    }
    setDetailsFicheEvaluation(copy_questions);
  };

  const handleSaveAndCloture = (cloturer = false) => {
    evaluation_refonte_reponse_service
      .insert_or_update(getInsertableDetailEvaluation(cloturer))
      .then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          handleClose();
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      })
      .catch((e) => {
        showToast(TYPE_NOTIFICATION.ERROR, e.toString());
      });
  };

  const handleSaveAllReponse = (cloturer = false) => {
    if (cloturer) {
      openConfirmDialog(
        "Confirmation",
        "Voullez vous enregistrer et envoyer cette evaluation? Vous ne pourriez plus le modifier une fois envoyé.",
        () => {
          handleSaveAndCloture(cloturer);
        },
        () => {}
      );
    } else {
      handleSaveAndCloture(cloturer);
    }
  };

  const handleCloseDialog = () => {
    handleClose();
    setDetailsFicheEvaluation([]);
  };

  useEffect(() => {
    handleFechFicheActiveSaison();
    handleFechEvaluateur();
    handleFechFicheEvaluation();

    if (candidat) {
      setSelectedCandidat(candidat);
    }
  }, [isOpen, candidat]);

  return (
    <>
      <Toast />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullScreen
        maxWidth="xl"
      >
        <DialogComponent />
        <LoadingSimpleBackdrop
          openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
        />
        <Paper>
          <Grid height={"100vh"} bgcolor={"#F7F7F8"}>
            <DialogTitle>Formulaire d'evaluation</DialogTitle>
            {!(!active_saison || detailsFicheEvaluation.length === 0) ? (
              <DialogContent>
                <Grid
                  height={"80vh"}
                  maxHeight={"80vh"}
                  overflow={"auto"}
                  p={1.5}
                >
                  <Paper
                    elevation={1}
                    sx={{
                      mb: 1,
                      borderTop: "5px solid #00a452",
                    }}
                  >
                    <Grid className="info-candidat" p={2}>
                      <CardHeader
                        title={"Identification du poste"}
                        titleTypographyProps={{
                          variant: "h6",
                          color: "primary",
                          gutterBottom: true,
                        }}
                        sx={{ mb: -2 }}
                      />
                      <Typography
                        variant="body1"
                        component={"div"}
                        sx={{ margin: "20px 30px" }}
                      >
                        Nom du titulaire du poste :
                        {(selectedCandidat.last_name || "") +
                          " " +
                          (selectedCandidat.first_name || "")}
                        <br />
                        Titre du poste : {selectedCandidat.function_name || ""}
                        <br />
                        Direction: {selectedCandidat.direction_code || ""}
                        <br />
                        Service: {selectedCandidat.service_code || ""}
                        <br />
                        Nom du Chef de Service :{" "}
                        {(selectedCandidat.sup_f_name ||
                          evaluateur.first_name ||
                          "") +
                          " " +
                          (selectedCandidat.sup_l_name ||
                            evaluateur.last_name ||
                            "")}
                        <br />
                        Titre du superviseur immédiat :{" "}
                        {selectedCandidat.sup_fonction ||
                          evaluateur.function_name ||
                          ""}
                        <br />
                      </Typography>
                    </Grid>
                  </Paper>

                  <Grid className="questionnaire">
                    {Array.from(
                      new Set(
                        detailsFicheEvaluation
                          .sort((a, b) =>
                            String(b.CATEGORY_NAME).localeCompare(
                              String(a.CATEGORY_NAME)
                            )
                          )
                          .map((detail) => detail.CATEGORY_NAME)
                      )
                    ).map((category, cat_idx) => (
                      <Paper
                        key={cat_idx}
                        elevation={1}
                        sx={{
                          mb: 1,
                          mt: 2,
                          borderLeft: "5px solid #00a452",
                        }}
                      >
                        <Grid m={1}>
                          <CardHeader
                            title={`Section ${cat_idx + 1}. ${category}`}
                            titleTypographyProps={{
                              variant: "h6",
                              color: "primary",
                              gutterBottom: true,
                            }}
                          />
                          {detailsFicheEvaluation
                            .filter((item) => item.CATEGORY_NAME === category)
                            .map((detail, det_idx) => (
                              <Grid
                                key={det_idx}
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                              >
                                <Grid display={"flex"} flexDirection={"column"}>
                                  <Grid>
                                    <CardHeader
                                      titleTypographyProps={{
                                        fontSize: "18px",
                                      }}
                                      subheaderTypographyProps={{
                                        fontSize: "13px",
                                        marginLeft: 3,
                                      }}
                                      title={`${det_idx + 1}. ${
                                        detail.QUESTION
                                      }`}
                                      subheader={`(Type reponse: ${String(
                                        detail.TYPE_NAME
                                      ).toLowerCase()})`}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  display={"flex"}
                                  justifyContent={"center"}
                                  textAlign={"center"}
                                  pb={4}
                                >
                                  <Grid
                                    display={
                                      detail.TYPE_NAME ===
                                      TYPE_REPONSE.note.name
                                        ? "flex"
                                        : "none"
                                    }
                                    flexDirection={"column"}
                                  >
                                    <SliderNotation
                                      disabled={detailsFicheEvaluation.some(
                                        (q) => q.TERMINEE === "1"
                                      )}
                                      max_val={Number(detail.NOTE_MAX)}
                                      min_val={Number(detail.NOTE_MIN)}
                                      val={
                                        detail.NOTE === null
                                          ? Number(
                                              detail.NOTE_CA || detail.NOTE_MIN
                                            )
                                          : Number(detail.NOTE)
                                      }
                                      on_leave={(n) => {
                                        handleUpdateEvaluationReponse(
                                          detail,
                                          n,
                                          TYPE_REPONSE.note
                                        );
                                      }}
                                    />
                                    <TextField
                                      disabled={detailsFicheEvaluation.some(
                                        (q) => q.TERMINEE === "1"
                                      )}
                                      fullWidth
                                      multiline
                                      sx={{
                                        mr: 3,
                                        mt: 1,
                                        display:
                                          selectedCandidat.user_id ===
                                          evaluateur.user_id
                                            ? "none"
                                            : "",
                                      }}
                                      rows={2}
                                      label="Votre commentaire"
                                      onChange={(e) => {
                                        handleUpdateEvaluationReponse(
                                          detail,
                                          e.target.value,
                                          TYPE_REPONSE.comment
                                        );
                                      }}
                                      value={
                                        detail.COMMENTAIRE_SUP ||
                                        detail.COMM_CA ||
                                        ""
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    className="jalon liste"
                                    display={
                                      detail.TYPE_NAME ===
                                      TYPE_REPONSE.jalon.name
                                        ? "flex"
                                        : "none"
                                    }
                                    flexDirection={"column"}
                                    width={"100%"}
                                  >
                                    {jalons
                                      .filter(
                                        (item) =>
                                          item.ID_QUESTIONNAIRE ===
                                          detail.ID_QUESTIONNAIRE
                                      )
                                      .map((jalon, j_idx) => (
                                        <Grid
                                          ml={4}
                                          display={"flex"}
                                          flexDirection={"row"}
                                          alignItems={"center"}
                                        >
                                          <Grid
                                            display={"flex"}
                                            alignContent={"center"}
                                          >
                                            <Checkbox
                                              disabled={detailsFicheEvaluation.some(
                                                (q) => q.TERMINEE === "1"
                                              )}
                                              checked={
                                                detail.REPONSE ===
                                                  jalon.VALEUR || ( !detail.REPONSE &&
                                                detail.REP_CA === jalon.VALEUR)
                                              }
                                              onChange={(e) =>
                                                handleUpdateEvaluationReponse(
                                                  detail,
                                                  e.target.checked
                                                    ? jalon.VALEUR
                                                    : null,
                                                  TYPE_REPONSE.jalon
                                                )
                                              }
                                            />
                                          </Grid>
                                          <Grid display={"flex"} flexGrow={1}>
                                            <Typography>
                                              {jalon.VALEUR}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ))}
                                  </Grid>
                                  <Grid
                                    width={"100%"}
                                    display={
                                      detail.TYPE_NAME ===
                                      TYPE_REPONSE.texte_libre.name
                                        ? "flex"
                                        : "none"
                                    }
                                    justifyContent={"center"}
                                  >
                                    <TextField
                                      InputProps={{
                                        readOnly: detailsFicheEvaluation.some(
                                          (q) => q.TERMINEE === "1"
                                        ),
                                      }}
                                      fullWidth
                                      multiline
                                      sx={{ mr: 3 }}
                                      rows={2}
                                      label="Votre reponse"
                                      onChange={(e) =>
                                        handleUpdateEvaluationReponse(
                                          detail,
                                          e.target.value,
                                          TYPE_REPONSE.texte_libre
                                        )
                                      }
                                      value={
                                        detail.REPONSE || detail.REP_CA || ""
                                      }
                                    ></TextField>
                                  </Grid>
                                </Grid>
                                <Divider
                                  sx={{
                                    display:
                                      detailsFicheEvaluation.filter(
                                        (item) =>
                                          item.CATEGORY_NAME === category
                                      ).length ===
                                      det_idx + 1
                                        ? "none"
                                        : "flex",
                                  }}
                                  variant="middle"
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Paper>
                    ))}
                  </Grid>
                  <Paper
                    elevation={1}
                    sx={{
                      mb: 1,
                      borderBottom: "5px solid #00a452",
                    }}
                  >
                    <Grid className="resultat">
                      <CardHeader
                        title={`Section ${
                          Array.from(
                            new Set(
                              detailsFicheEvaluation.map(
                                (detail) => detail.CATEGORY_NAME
                              )
                            )
                          ).length + 1
                        }. Le niveau du rendement du Consultant est la somme des évaluations`}
                        titleTypographyProps={{
                          variant: "body1",
                          color: "blue",
                          fontSize: "20px",
                        }}
                      />

                      <Grid className="tableau-rendement" m={4}>
                        <table
                          border="1"
                          style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                          <tr>
                            <th>Section</th>
                            <th>Somme des notes données</th>
                            <th>Somme des notes maximum</th>
                          </tr>
                          {Array.from(
                            new Set(
                              detailsFicheEvaluation
                                .filter((detail) =>
                                  String(detail.TYPE_NAME)
                                    .toLowerCase()
                                    .includes("note")
                                )
                                .map((detail) => detail.CATEGORY_NAME)
                            )
                          ).map((category, c_idx) => {
                            return (
                              <tr key={c_idx}>
                                <td>{category}</td>
                                <td style={{ width: "20%" }}>
                                  {detailsFicheEvaluation
                                    .filter(
                                      (item) =>
                                        item.CATEGORY_NAME === category &&
                                        String(item.TYPE_NAME)
                                          .toLowerCase()
                                          .includes("note")
                                    )
                                    .reduce(
                                      (somme, det) =>
                                        somme + Number(det.NOTE || det.NOTE_CA),
                                      0
                                    )}
                                </td>
                                <td style={{ width: "20%" }}>
                                  {`Sur ${detailsFicheEvaluation
                                    .filter(
                                      (item) =>
                                        item.CATEGORY_NAME === category &&
                                        String(item.TYPE_NAME)
                                          .toLowerCase()
                                          .includes("note")
                                    )
                                    .reduce(
                                      (somme, det) =>
                                        somme + Number(det.NOTE_MAX),
                                      0
                                    )}`}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              Total des critères d’évaluation du Consultant{" "}
                            </td>
                            <td>
                              {detailsFicheEvaluation
                                .filter((item) =>
                                  String(item.TYPE_NAME)
                                    .toLowerCase()
                                    .includes("note")
                                )
                                .reduce(
                                  (somme, det) =>
                                    somme + Number(det.NOTE || det.NOTE_CA),
                                  0
                                )}
                            </td>
                            <td>
                              {`Sur ${detailsFicheEvaluation
                                .filter((item) =>
                                  String(item.TYPE_NAME)
                                    .toLowerCase()
                                    .includes("note")
                                )
                                .reduce(
                                  (somme, det) => somme + Number(det.NOTE_MAX),
                                  0
                                )}`}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              L'appréciation générale du Consultant est la somme
                              de :
                            </td>
                            <td>
                              {detailsFicheEvaluation
                                .filter((item) =>
                                  String(item.TYPE_NAME)
                                    .toLowerCase()
                                    .includes("note")
                                )
                                .reduce(
                                  (somme, det) =>
                                    somme + Number(det.NOTE || det.NOTE_CA),
                                  0
                                )}
                            </td>
                            <td>
                              {`Sur ${detailsFicheEvaluation
                                .filter((item) =>
                                  String(item.TYPE_NAME)
                                    .toLowerCase()
                                    .includes("note")
                                )
                                .reduce(
                                  (somme, det) => somme + Number(det.NOTE_MAX),
                                  0
                                )}`}
                            </td>
                          </tr>
                        </table>
                      </Grid>

                      <Grid
                        className="classification-finale"
                        display={"flex"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        m={1}
                      >
                        <CardHeader
                          title={`Section ${
                            Array.from(
                              new Set(
                                detailsFicheEvaluation.map(
                                  (detail) => detail.CATEGORY_NAME
                                )
                              )
                            ).length + 2
                          }. Classification finale`}
                          titleTypographyProps={{
                            variant: "body1",
                            color: "blue",
                            fontSize: "20px",
                          }}
                        />
                        <Grid
                          container
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          <Paper>
                            <Grid display={"flex"} flexDirection={"row"} m={2}>
                              <Grid m={1} textAlign={"center"}>
                                <Typography>Note general</Typography>
                                {detailsFicheEvaluation
                                  .filter((item) =>
                                    String(item.TYPE_NAME)
                                      .toLowerCase()
                                      .includes("note")
                                  )
                                  .reduce(
                                    (somme, det) =>
                                      somme + Number(det.NOTE || det.NOTE_CA),
                                    0
                                  ) +
                                  "/" +
                                  detailsFicheEvaluation
                                    .filter((item) =>
                                      String(item.TYPE_NAME)
                                        .toLowerCase()
                                        .includes("note")
                                    )
                                    .reduce(
                                      (somme, det) =>
                                        somme + Number(det.NOTE_MAX),
                                      0
                                    )}
                              </Grid>
                              <Divider
                                orientation="vertical"
                                variant="middle"
                              />
                              <Grid m={1} textAlign={"center"}>
                                <Typography>Observation</Typography>
                                {findOutEvaluationGenerale(
                                  detailsFicheEvaluation
                                    .filter((item) =>
                                      String(item.TYPE_NAME)
                                        .toLowerCase()
                                        .includes("note")
                                    )
                                    .reduce(
                                      (somme, det) =>
                                        somme + Number(det.NOTE || det.NOTE_CA),
                                      0
                                    ),
                                  detailsFicheEvaluation
                                    .filter((item) =>
                                      String(item.TYPE_NAME)
                                        .toLowerCase()
                                        .includes("note")
                                    )
                                    .reduce(
                                      (somme, det) =>
                                        somme + Number(det.NOTE_MAX),
                                      0
                                    )
                                )}
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </DialogContent>
            ) : (
              <DialogContent>
                <Grid
                  height={"80vh"}
                  maxHeight={"80vh"}
                  overflow={"auto"}
                  p={1.5}
                >
                  <Grid>
                    <Alert severity="error">
                      <AlertTitle>Questionnaire non trouvé</AlertTitle>
                      Aucune question disponible pour evaluer cette personne
                      (raison : aucune saison ou fiche d'evaluation active ).
                      Veuillez contacter le responsable de l'evaluation du
                      personnel
                    </Alert>
                  </Grid>
                </Grid>
              </DialogContent>
            )}
            <DialogActions>
              <Button
                disabled={
                  detailsFicheEvaluation.some((q) => q.TERMINEE === "1") ||
                  detailsFicheEvaluation.length === 0
                }
                onClick={(e) => handleSaveAllReponse(false)}
                color="primary"
                variant="contained"
              >
                Enregistrer
              </Button>
              <Button
                disabled={
                  detailsFicheEvaluation.some((q) => q.TERMINEE === "1") ||
                  detailsFicheEvaluation.length === 0
                }
                onClick={(e) => handleSaveAllReponse(true)}
                color="secondary"
                variant="contained"
              >
                Enregistrer & envoyer
              </Button>
              <Button
                onClick={(e) => handleCloseDialog()}
                color="primary"
                variant="outlined"
              >
                Fermer
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default EvaluationExaminationForm;
