import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AutocompleteCombobox, {
  adaptDataValueToAutocompleteData,
} from "../shared/AutocompleteCombobox";

import {
  evaluation_categorie_critere_service,
  evaluation_questionnaire_service,
  evaluation_saison_service,
  evaluation_utilities_service,
} from "../../../services/PersonalEvaluationService";
import QuestionnaireAssignees from "./QuestionnaireAssignees";
import QuestionnaireNonAssignees from "./QuestionnaireNonAssignees";
import { RefreshOutlined } from "@material-ui/icons";
import QuestionnaireFormAjout from "./QuestionnaireFormAjout";
import AssignerParGroupeDeCritere from "./AssignerParGroupeDeCritere";

const QuestionnaireForm = ({ isOpen, current_saison, handleClose }) => {
  const [categoryCriteres, setCategoryCriteres] = useState([]);
  const [evaluationsSaisons, setEvaluationSaisonsList] = useState([]);
  const [fonctions, setFonctionsList] = useState([]);
  const [assigned_criterias, setAssignedCriterias] = useState([]);
  const [un_assigned_criterias, setUnAssignedCriterias] = useState([]);
  const [openFormAjout, setOpenFormAjout] = useState(false);
  const [openFormAssignGroupee, setOpenFormAssignGroupee] = useState(false);
  const [current_questionnaire, setCurrentQuestionnaire] = useState({
    saison: null,
    fonction: null,
    critere: null,
    note_min: 0,
    note_max: 0,
  });

  const [filtre, setFiltre] = useState({
    saison: null,
    fonction: null,
    category: null,
    mot_cle: "",
    refresh: 0,
  });

  useEffect(() => {
    handleInitFilterFields();
  }, []);

  useEffect(() => {
    if (filtre) {
      console.log("actualisation ", filtre);

      handleLoadDataToTableAssignedCriterias();
    }
  }, [filtre]);

  const handleSetCurrentQestionnaire = (questionnaire) => {
    setCurrentQuestionnaire(questionnaire);
  };

  const reinitialiseFiltre = () => {
    setFiltre({
      saison: null,
      fonction: null,
      category: null,
      mot_cle: "",
      refresh: 0,
    });
  };

  const handleLoadDataToTableAssignedCriterias = () => {
    evaluation_questionnaire_service
      .getQuestionnaireList(filtre)
      .then((res) => {
        if (res) {
          handleLoadDataToTableUnAssignedCriterias(
            res.data.map((criteria) => criteria.ID_CRITERE)
          );
          setAssignedCriterias(res.data);
        }
      });
  };
  const handleLoadDataToTableUnAssignedCriterias = (ids_list) => {
    evaluation_questionnaire_service
      .getExcludedByIdsQuestionnaireList(ids_list)
      .then((res) => {
        if (res) {
          setUnAssignedCriterias(res.data);
        }
      });
  };

  const handleCloseFormAjoutCritereGroupee = () => {
    setOpenFormAssignGroupee(false);
    handleLoadDataToTableAssignedCriterias();
  };

  const handleCloseFormAjout = () => {
    setOpenFormAjout(false);
    handleRefreshData();
  };
  const handleOpenFormAjout = () => {
    setOpenFormAjout(true);
  };

  const handleDialogClose = () => {
    reinitialiseFiltre();
    handleClose();
  };

  const handleChangeSaison = (new_val) => {
    setFiltre({ ...filtre, saison: new_val });
  };
  const handleChangeFonciton = (new_val) => {
    setFiltre({ ...filtre, fonction: new_val });
  };
  const handleChangeCategory = (new_val) => {
    setFiltre({ ...filtre, category: new_val });
  };

  const handlechangeFiltre = (evt) => {
    const { name, value } = evt.target;
    setFiltre({ ...filtre, [name]: value });
  };

  const handleRefreshData = () => {
    setFiltre({ ...filtre, refresh: filtre.refresh + 1 });
  };

  const handleAssignerQuestionnaireGroupee = () => {
    setOpenFormAssignGroupee(true);
  };

  const handleInitFilterFields = () => {
    evaluation_categorie_critere_service
      .get_evaluation_category_critere_list(null)
      .then((res) => {
        if (res && res.data) {
          setCategoryCriteres(res.data);
        }
      })
      .catch((e) => {
        console.log(e.toString());
      });

    evaluation_saison_service.getSaisonList(filtre).then((res) => {
      if (res && res.success) {
        setEvaluationSaisonsList(res.data);
      }
    });
    evaluation_utilities_service.getFonctionList(filtre).then((res) => {
      if (res && res.success) {
        setFonctionsList(res.data);
      }
    });
  };

  return (
    <Dialog
      open={isOpen}
      /* onClose={ handleDialogClose} */
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <AssignerParGroupeDeCritere
        formOpen={openFormAssignGroupee}
        onFormClose={handleCloseFormAjoutCritereGroupee}
        fonction={filtre.fonction}
        saison={filtre.saison}
      />
      <QuestionnaireFormAjout
        current_questionnaire={current_questionnaire}
        handleClose={handleCloseFormAjout}
        isOpen={openFormAjout}
      />
      <Paper>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          height={"90vh"}
          overflow={"auto"}
        >
          <DialogTitle id="dialog-title" className="entete-dialog">
            {"Assigner questionnaires"}
          </DialogTitle>
          <DialogContent
            id="dialog-description"
            className="app-scroll-bar-view"
            sx={{ overflow: "auto" }}
          >
            <Grid
              item
              mt={0.5}
              display={"flex"}
              flexDirection={"column"}
              height={"60vh"}
            >
              <Grid container display={"flex"} flexDirection={"row"}>
                <Grid item flexGrow={1} m={1}>
                  <Typography className="form-control">
                    Choisir une saison
                  </Typography>
                  <AutocompleteCombobox
                    limitTags={2}
                    label="Saisons"
                    class_name="filtre_list"
                    data={adaptDataValueToAutocompleteData(
                      evaluationsSaisons,
                      "NOM_SESSION",
                      "ID_SAISON"
                    )}
                    onChange={handleChangeSaison}
                  />
                </Grid>
                <Grid item flexGrow={1} m={1}>
                  <Typography className="form-control">
                    Choisir une fonction
                  </Typography>
                  <AutocompleteCombobox
                    limitTags={2}
                    label="Fonction"
                    class_name="filtre_list"
                    data={adaptDataValueToAutocompleteData(
                      fonctions,
                      "name",
                      "function_id"
                    )}
                    onChange={handleChangeFonciton}
                  />
                </Grid>
                <Grid item mb={2.5} alignContent={"flex-end"}>
                  <Button
                    disabled={!(filtre.fonction && filtre.saison)}
                    sx={{ mr: 1 }}
                    variant="outlined"
                    color="primary"
                    onClick={handleAssignerQuestionnaireGroupee}
                  >
                    Assigner critere groupé
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleRefreshData}
                  >
                    <RefreshOutlined />
                  </Button>
                </Grid>
              </Grid>
              <Grid
                mt={1}
                flexGrow={1}
                display={"flex"}
                flexDirection={"column"}
              >
                <Grid
                  container
                  flexGrow={1}
                  display={"flex"}
                  flexDirection={"row"}
                >
                  <Grid item xs={12} lg={6} flexGrow={1}>
                    <Grid m={1} pt={4} height={"45vh"}>
                      <CardHeader
                        title={"Liste des questionnaires non assignés"}
                        titleTypographyProps={{ variant: "body1" }}
                      />
                      <QuestionnaireNonAssignees
                        filtre={filtre}
                        row_datas={un_assigned_criterias}
                        handleSetCurrentQuestionnaire={
                          handleSetCurrentQestionnaire
                        }
                        handleOpenform={handleOpenFormAjout}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6} flexGrow={1}>
                    <Grid m={1} pt={4} height={"45vh"}>
                      <CardHeader
                        title={"Liste des questionnaires assignés"}
                        titleTypographyProps={{ variant: "body1" }}
                      />
                      <QuestionnaireAssignees
                        filtre={filtre}
                        handleOpenform={handleOpenFormAjout}
                        row_datas={assigned_criterias}
                        handleSetCurrentQuestionnaire={
                          handleSetCurrentQestionnaire
                        }
                        actualiseFormData={handleRefreshData}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider variant="middle" />
          <DialogActions sx={{ mr: 2 }}>
            <Button
              className="form_control"
              onClick={handleDialogClose}
              variant="outlined"
            >
              Fermer
            </Button>
          </DialogActions>
        </Grid>
      </Paper>
    </Dialog>
  );
};

export default QuestionnaireForm;
