import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const LeaveService = {
  async add(
    leave_type_id,
    begin_time,
    begin_time_session,
    end_time,
    end_time_session,
    is_priority,
    balance_before,
    balance_after,
    duration,
    reason
  ) {
    try {
      const result = await axios.post(`${apiUrl}/Leave/`, {
        leave_type_id,
        begin_time,
        begin_time_session,
        end_time,
        end_time_session,
        is_priority,
        balance_before,
        balance_after,
        duration,
        reason,
      });
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveLeave(
    leave_type_id,
    begin_time,
    begin_time_session,
    end_time,
    end_time_session,
    is_priority,
    balance_before,
    balance_after,
    duration,
    reason,
    is_valid,
    is_valid_edited,
    file,
    id,
    isRealRequest,
    isLeavePlanningEdited,
    isValidatorNumberConfigurate,
    dayOffNumberWithoutValidation
  ) {
    try {
      // console.log("ato lay file",file)
      const result = id
        ?
        await axios.put(`${apiUrl}/Leave/id/${id}`, {
          leave_type_id,
          begin_time,
          begin_time_session,
          end_time,
          end_time_session,
          is_priority,
          balance_before,
          balance_after,
          duration,
          reason,
          is_valid,
          is_valid_edited,
          file,
          isLeavePlanningEdited
        })
        : await axios.post(`${apiUrl}/Leave/`, {
          leave_type_id,
          begin_time,
          begin_time_session,
          end_time,
          end_time_session,
          is_priority,
          balance_before,
          balance_after,
          duration,
          reason,
          isRealRequest,
          isValidatorNumberConfigurate,
          dayOffNumberWithoutValidation
        });
      if (result) {
        // TODO: replace result.data.message by the token

        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getListe(id) {
    try {
      const result = await axios.get(`${apiUrl}/Leave/liste/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async deleteLeave(id) {
    try {
      const result = await axios.delete(`${apiUrl}/leave/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data);
      }
      throw error;
    }
  },

  async getLeaveType() {
    try {
      const result = await axios.get(`${apiUrl}/LeaveType/`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getUserLeaveDelta() {
    try {
      const result = await axios.get(`${apiUrl}/LeaveDelta/getById`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getLeavesByOptions(options) {
    try {
      const result = await axios.get(`${apiUrl}/Leave`, { params: options });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getLeaveById(id) {
    try {
      const result = await axios.get(`${apiUrl}/leave/id/${id}`);
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getLeavePrevisional(user_id, begin_time, end_time) {
    try {
      const result = await axios.post(
        `${apiUrl}/Leave/getToValidateLeaveBetweenDates`,
        {
          user_id,
          begin_time,
          end_time,
        }
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async validateFile(leaveID, filename, leaveCode, qrCode, isValidatorNumberConfigurate, dayOffNumberWithoutValidation) {
    try {
      // const result = await axios.post(`${apiUrl}/Leave/validateLeave/`, { leaveID, filename, module, leaveCode });
      const result = await axios.post(`${apiUrl}/Leave/validateLeave/`, { leaveID, filename, leaveCode, qrCode,isValidatorNumberConfigurate, dayOffNumberWithoutValidation });
      if (result) {
        // TODO: replace result.data.message by the token

        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  // <Sidonie>
  async getBalanceBefore() {
    try {
      const result = await axios.get(`${apiUrl}/LeaveSolde/soldeavant`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getBalanceAfter(count) {
    try {
      const result = await axios.post(`${apiUrl}/LeaveSolde/solde`, {
        "nombre_jour": count,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getRealBalanceBefore() {
    try {
      const result = await axios.get(`${apiUrl}/LeaveSolde/balancereel`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getRealBalanceAfter(count) {
    try {
      const result = await axios.post(`${apiUrl}/LeaveSolde/balanceafter`, {
        "nombre_jour": count,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getstatus(id) {
    try {
      const result = await axios.get(`${apiUrl}/LeaveSolde/status/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getparam() {
    try {
      const result = await axios.get(`${apiUrl}/leave/param`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getreel() {
    try {
      const result = await axios.get(`${apiUrl}/leave/reel`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getLeaveObselete(raison, codeobselete, fileRequestId, leave, isPlanning) {
    try {
      const result = await axios.post(
        `${apiUrl}/Leave/gettoobseletCancel`,
        {
          raison,
          codeobselete,
          fileRequestId,
          leave,
          isPlanning
        }
      );
      if (result) {
        // TODO: replace result.data.message by the token
        return result;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  // <Sidonie>
  //<Miora>
  async getLeaveDay(begin_time, end_time) {
    try {
      const result = await axios.get(`${apiUrl}/Leave/Time/${begin_time}/${end_time}`);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getLeave(file_request_id) {
    try {
      const result = await axios.get(`${apiUrl}/Leave/LeaveFile/${file_request_id}`);
      if (result) {
        // TODO: replace result.data.message by the token
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getStateLeave(leave_id) {
    try {
      const result = await axios.get(`${apiUrl}/leave/getStateLeave`,{
        params: {
          leaveID: leave_id
        }
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getActualLeave(mois, jours, beginDate, endDate) {
    try {
      const result = await axios.get(`${apiUrl}/leave/leavemonth/${mois}/${jours}/${beginDate}/${endDate}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getMonth(beginDate, endDate) {
    try {
      const result = await axios.get(`${apiUrl}/leave/month/${beginDate}/${endDate}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  /**
   * Function to get list of planning leaves: state=1 pour planning validé, state=0 pour les non validés
   * @param {*} state 
   * @param {*} jours 
   * @param {*} mois 
   * @param {*} date_start 
   * @param {*} date_end 
   * @author Samuela
   * @returns 
   */

  async getPlanningLeave(state, jours, mois, date_start, date_end) {
    try {
      const result = await axios.get(`${apiUrl}/leave/getPlanningLeave/${state}/${jours}/${mois}/${date_start}/${date_end}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  /**
   * Function to get monthly report of leaves for all user
   * @param {*} month 
   * @param {*} currentDirectionId
   * @author Samuela
   * @returns 
   */

  async getMonthlyReportForAllUser(month, currentDirectionId,yearselect) {
    try {
      const result = await axios.get(`${apiUrl}/leave/getMonthlyReportForAllUser/${month}/${currentDirectionId}/${yearselect}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  /**
   * Function to export monthly report of leaves for all user
   * @param {*} month 
   * @param {*} currentDirectionId
   * @author Samuela
   * @returns 
   */

  async doExport(type, month, content, currentDirectionId) {
    try {
      const result = await axios.post(`${apiUrl}/leave/doExportMonthlyReport`, {
        type: type,
        month: month,
        content: content,
        directionId: currentDirectionId
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  /**
   * Function to get monthly report of leaves for all user
   * @param {*} semester 
   * @param {*} currentDirectionId
   * @author Samuela
   * @returns 
   */

  async getLeaveSemesterForAllUser(semester, currentDirectionId) {
    try {
      const result = await axios.get(`${apiUrl}/leave/getLeaveSemesterForAllUser/${semester}/${currentDirectionId}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  /**
   * Function to export semester leave for all user
   * @param {*} month 
   * @param {*} currentDirectionId
   * @author Samuela
   * @returns 
   */

  async doExportLeaveSemester(type, semester, content, currentDirectionId) {
    try {
      const result = await axios.post(`${apiUrl}/leave/doExportSemesterLeave`, {
        type: type,
        semester: semester,
        content: content,
        directionId: currentDirectionId
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  /**
   * Function to export planning for validation 
   * @param {*} month 
   * @param {*} currentDirectionId
   * @author Samuela
   * @returns 
   */

   async doExportPlanningForValidation(type, monthBegin, monthEnd, content, currentDirectionId, currentServiceId) {
    try {
      const result = await axios.post(`${apiUrl}/leave/doExportPlanningForValidation`, {
        type: type,
        monthBegin: monthBegin,
        monthEnd: monthEnd,
        content: content,
        directionId: currentDirectionId,
        serviceId: currentServiceId
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  /**
   * Function to export planning for validation 
   * @param {*} month 
   * @param {*} currentDirectionId
   * @author Samuela
   * @returns 
   */

   async doExportPlanningForValidationGlobalView(type, monthBegin, monthEnd,period, content, currentDirectionId, currentServiceId,listPendingPlanningUserId) {
    try {
      const result = await axios.post(`${apiUrl}/leave/doExportPlanningForValidationGlobalView`, {
        type: type,
        monthBegin: monthBegin,
        monthEnd: monthEnd,
        period: period,
        content: content,
        directionId: currentDirectionId,
        serviceId: currentServiceId,
        listPendingPlanningUserId: listPendingPlanningUserId
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  

  async getDayOfMonth(month) {
    try {
      const result = await axios.get(`${apiUrl}/leave/getDayOfMonth`, {
        params: {
          month: month
        }
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  /**
   * Function to get monthly planning of leaves for all user
   * @param {*} month 
   * @param {*} currentDirectionId
   * @author Samuela
   * @returns 
   */

  async getPlanningReportForAllUser(month, day, idDirection, idService) {
    try {
      const result = await axios.get(`${apiUrl}/leave/getPlanningReportForAllUser/${month}/${day}`, {
        params: {
          direction_id: idDirection,
          service_id: idService
        }
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  


  /**
   * Function to export semester planning for all user
   * @param {*} month 
   * @param {*} currentDirectionId
   * @author Samuela
   * @returns 
   */

  async doExportPlanningSemester(type, semester,monthName, content, currentDirectionId, dayList) {
    try {
      const result = await axios.post(`${apiUrl}/leave/doExportSemesterPlanning`, {
        type: type,
        semester: semester,
        content: content,
        directionId: currentDirectionId,
        dayList: dayList,
        monthName: monthName
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  /**
   * Function to get previsional balance of user connected by date
   * @param {*} begin_date 
   * @author Samuela
   * @returns 
   */

   async getPrevisonalBalance(begin_date) {
    try {
      const result = await axios.get(`${apiUrl}/leave/getPrevisionnalBalanceBefore/${begin_date}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  

  /**
   * Function to get planning for validation of leaves for user inf
   * @param {*} month 
   * @param {*} currentDirectionId
   * @author Samuela
   * @returns 
   */

   async getPlanningForValidation(month, day, idDirection, idService, planningYear, listPlanningId) {
    try {
      const result = await axios.get(`${apiUrl}/leave/getPlanningForValidation/${planningYear}/${month}/${day}`, {
        params: {
          direction_id: idDirection,
          service_id: idService,
          list_planning_id: listPlanningId 
        }
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  /**
   * Function to get leave detail by leave id
   * @param {*} leaveID 
   * @author Samuela
   * @returns 
   */

   async getLeaveDetailById(leaveID) {
    try {
      const result = await axios.get(`${apiUrl}/leave/getLeaveDetailById/${leaveID}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async setannualleave(observation,solde,dates,datedebut,dateend) {
    try {
      const result = await axios.post(`${apiUrl}/leave/setannualleave`,{
        observation:observation,
        solde:solde,
        dates:dates,
        dateend: dateend ,
        datedebut:datedebut
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getannualleave() {
    try {
      const result = await axios.get(`${apiUrl}/leave/getannualleave`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async trowannualleave(id) {
    try {
      const result = await axios.get(`${apiUrl}/leave/lanceannualleave/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async deleteannualleave(id) {
    try {
      const result = await axios.get(`${apiUrl}/leave/deleteanual/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async detailannualleave(id) {
    try {
      const result = await axios.get(`${apiUrl}/leave/detailannual/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async updateannualleave(id,observation,solde,dates,datedebut,dateend) {
    try {
      const result = await axios.put(`${apiUrl}/leave/updateanual/`+id,{
        observation:observation,
        solde:solde,
        dates:dates,
        dateend: dateend ,
        datedebut:datedebut
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

};

export default LeaveService;
