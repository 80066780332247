import { useState, useCallback, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

useConfirmation.defaultProps = {
  hideCancel: true,
};

function useConfirmation() {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogOptions, setDialogOptions] = useState({
    title: "",
    content: "",
    onConfirm: null,
    onAbord: null,
  });

  const openConfirmDialog = useCallback(
    (title, content, onConfirm, onAbord) => {
      setDialogOptions({ title, content, onConfirm, onAbord });
      setIsOpen(true);
    },
    []
  );

  const closeDialog = () => {
    setIsOpen(false);
    setDialogOptions({
      title: "",
      content: "",
      onConfirm: null,
      onAbord: null,
    });
  };

  const handleConfirm = () => {
    if (dialogOptions.onConfirm) dialogOptions.onConfirm();
    closeDialog();
  };
  const handleAbord = () => {
    if (dialogOptions.onConfirm) dialogOptions.onAbord();
    closeDialog();
  };

  const DialogComponent = () => (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>{dialogOptions.title}</DialogTitle>
      <DialogContent>{dialogOptions.content}</DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">
          Oui
        </Button>
        <Button onClick={handleAbord} color="secondary">
          Non
        </Button>
        {/* <Button onClick={closeDialog} color="primary">
          Annuler
        </Button> */}
      </DialogActions>
    </Dialog>
  );

  return { openConfirmDialog, DialogComponent };
}

export default useConfirmation;
