import { DataGrid } from "@material-ui/data-grid";
import ValidatorsForm from "./ValidatorsForm";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { exportToExcel } from "../../helpers/ExportHelpers";
import { Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { EditOutlined } from "@material-ui/icons";
import LoadingSimpleBackdrop from "../tools/LoadingSimpleBackdrop";
import { circuit_validation_service } from "../../services/CircuitValidationService";
import useToast from "../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../components/reusable/NotificationBull";

const ValidatorTable = ({
  openForm,
  onCloseForm,
  handleOpenDialog,
  excelExport,
  filtre,
}) => {
  const { Toast, showToast } = useToast();
  const [evaluators, setEvaluators] = useState([]);
  const [formOpen, setFormOpen] = useState(openForm);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);

  const handleOpenSaisonForm = () => {
    handleOpenDialog();
    setFormOpen(true);
  };

  const handleCloseFormDIalog = () => {
    onCloseForm();
    handleFetchEvaluators(filtre);
    setFormOpen(false);
  };

  const handleFetchDefaultEvaluatorsList = (filtre) => {
    setOpenLoadingSimpleBackdrop(true);
    circuit_validation_service
      .get_default_validators_list(filtre)
      .then((res) => {
        if (res && res.data) {
          setEvaluators(res.data);
        }
      })
      .finally(() => {
        setOpenLoadingSimpleBackdrop(false);
      });
  };

  const handleFetchEvaluators = (filtre) => {
    setOpenLoadingSimpleBackdrop(true);
    circuit_validation_service
      .get_personnalized_validators_list(filtre)
      .then((res) => {
        if (res && res.data) {
          if (Array.from(res.data).length > 0) {
            setEvaluators(res.data);
          } else {
            showToast(
              TYPE_NOTIFICATION.INFO,
              "Validateur personnalisé non definie"
            );
            handleFetchDefaultEvaluatorsList(filtre);
          }
        }
      })
      .finally(() => {
        setOpenLoadingSimpleBackdrop(false);
      });
  };

  useEffect(() => {
    if (filtre) {
      handleFetchEvaluators(filtre);
    }

    if (openForm) {
      setFormOpen(openForm);
    }

    if (excelExport) {
      exportToExcel(evaluators, columns, "Liste des evaluateurs " + new Date());
    }
  }, [filtre, openForm, excelExport]);

  const columns = [
    {
      field: "full_name",
      headerName: "Nom et prénom ",
      width: 430,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "function_name",
      headerName: "Fonction",
      width: 450,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "niveau",
      headerName: "Niveau",
      width: 145,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
  ];
  return (
    <>
      <Toast />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <ValidatorsForm
        isOpen={formOpen}
        handleClose={handleCloseFormDIalog}
      />
      <DataGrid
        rows={evaluators}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        getRowId={(row) => row.ID_PERSONNALIZED_VALIDATOR}
      />
    </>
  );
};

export default ValidatorTable;
