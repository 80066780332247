import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const TimeTrackingService = {
  
  async getAllTimeTracking(filters) {
    try {
      // Utilisez les filtres pour construire l'URL de la requête
      const queryString = Object.keys(filters)
        .map((key) => `${key}=${encodeURIComponent(filters[key])}`)
        .join("&");

      const result = await axios.get(`${apiUrl}/TimeTracking/getAllTimeTracking?${queryString}`);
      if (result) {
        return result.data;

      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },
  async setTimePrevisionaly(date,observation)
  {
    try{
       const result = await axios.post(`${apiUrl}/TimetrackingProvisional/`,{
           date:date,
           observation:observation
        });
          if (result) {
            return result.data;
          }
      }catch (error) {
          if (error.response) {
              return error.response.data;
          }
      }
  },
  async getTimePrevisionaly()
  {
    try{
      const result = await axios.get(`${apiUrl}/TimetrackingProvisional/`);
         if (result) {
           return result.data;
         }
     }catch (error) {
         if (error.response) {
             return error.response.data;
         }
     } 
  },
  async deleteTimePrevisionaly(id)
  {
    try{
      const result = await axios.get(`${apiUrl}/TimetrackingProvisional/delete/`+id);
         if (result) {
           return result.data;
         }
     }catch (error) {
         if (error.response) {
             return error.response.data;
         }
     } 
  },
  async getByIDTimePrevisionaly(id)
  {
    try{
      const result = await axios.get(`${apiUrl}/TimetrackingProvisional/getById/`+id);
         if (result) {
           return result.data;
         }
     }catch (error) {
         if (error.response) {
             return error.response.data;
         }
     } 
  },
  async updateTimePrevisionaly(id,date,observation)
  {
    try{
      const result = await axios.put(`${apiUrl}/TimetrackingProvisional/`+id,{
        date:date,
        observation:observation
      });
         if (result) {
           return result.data;
         }
     }catch (error) {
         if (error.response) {
             return error.response.data;
         }
     } 
  },
  async sendvalidate(id)
  {
    try{
      const result = await axios.get(`${apiUrl}/TimetrackingProvisional/sendvalidate/`+id);
         if (result) {
           return result.data;
         }
     }catch (error) {
         if (error.response) {
             return error.response.data;
         }
     } 
  },
  async getlistvalidate(debut,fin,demandeur)
  {
    try{
      const result = await axios.post(`${apiUrl}/TimetrackingProvisional/getlistvalidate`,{
        debut:debut,
        fin:fin,
        demandeur:demandeur
      });
         if (result) {
           return result.data;
         }
     }catch (error) {
         if (error.response) {
             return error.response.data;
         }
     } 
  },

  async validate(id,timetracking)
  {
    try{
      const result = await axios.get(`${apiUrl}/TimetrackingProvisional/validatetimetracking/`+id+'/'+timetracking);
         if (result) {
           return result.data;
         }
     }catch (error) {
         if (error.response) {
             return error.response.data;
         }
     } 
  },

  
  async refused(id,observation)
  {
    try{
      const result = await axios.post(`${apiUrl}/TimetrackingProvisional/refusedtimetracking/`+id,
        {observation:observation}
      );
         if (result) {
           return result.data;
         }
     }catch (error) {
         if (error.response) {
             return error.response.data;
         }
     } 
  },
  async tousvalidate(rowsid)
  {
    try{
      const result = await axios.post(`${apiUrl}/TimetrackingProvisional/tousvalidate`,
        {rowsId:rowsid}
      );
         if (result) {
           return result.data;
         }
     }catch (error) {
         if (error.response) {
             return error.response.data;
         }
     } 
  },
  
}
export default TimeTrackingService;