import {
  Button,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AutocompleteCombobox, {
  adaptDataValueToAutocompleteData,
} from "../shared/AutocompleteCombobox";
import {
  evaluation_refonte_category_service,
  evaluation_refonte_fiche_evaluation_service,
  evaluation_refonte_questionnaire_par_fiche_service,
  evaluation_refonte_questionnaire_service,
} from "../../../services/PersonalEvaluationRefonteService";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import { RefreshOutlined } from "@material-ui/icons";

const AssignerCritereToFiche = ({ isOpen, current_fiche, handle_close }) => {
  const [categories, setCategories] = useState([]);
  const [fiches, setFiches] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [motCle, setMotCle] = useState("");
  const [filtre, setFiltre] = useState({
    QUESTION: "",
    CATEGORY: {},
  });
  const { Toast, showToast } = useToast();

  const handleLoadAllQuesitons = () => {
    evaluation_refonte_questionnaire_service
      .getListByFiche(filtre)
      .then((res) => {
        if (res) {
          setQuestions(res.data || []);
        }
      });
  };

  const handleLoadAllData = () => {
    evaluation_refonte_fiche_evaluation_service
      .getList({ mot_cle: "" })
      .then((res) => {
        if (res) {
          setFiches(res.data || []);
        }
      });
    evaluation_refonte_category_service.getList({ mot_cle: "" }).then((res) => {
      if (res) {
        setCategories(res.data || []);
      }
    });
    handleLoadAllQuesitons();
  };

  const handleCloseDialog = () => {
    setFiltre([]);
    handle_close();
  };

  const handleChangeCategoryCritere = (new_val) => {
    setFiltre({ ...filtre, CATEGORY: new_val });
  };

  const handleChangeFiche = (new_val) => {
    setFiltre({ ...filtre, FICHE: new_val });
  };

  const handleAssignQuestion = (evt, item) => {
    const val = evt.target.checked;
    let copy_question = questions.slice();
    let current_index = copy_question.indexOf(item);
    let curr_item = copy_question[current_index];
    curr_item.ID_FICHE_EVALUATION = val
      ? filtre.FICHE.ID_FICHE_EVALUATION
      : null;
    setQuestions(copy_question);
  };

  const handleSaveAssignedQuestionToFiche = () => {
    evaluation_refonte_questionnaire_par_fiche_service
      .insert_or_delete(filtre.FICHE.ID_FICHE_EVALUATION, questions)
      .then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS, res.message);
          handleCloseDialog();
        } else {
          showToast(TYPE_NOTIFICATION.ERROR, res.message);
        }
      });
  };

  useEffect(() => {
    if (filtre || isOpen) {
      handleLoadAllQuesitons();
    }
    if (isOpen) {
      handleLoadAllData();
    }
  }, [isOpen, filtre]);

  return (
    <>
      <Toast />
      <Dialog
        open={isOpen}
        /* onClose={ handleDialogClose} */
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <Paper>
          <Grid>
            <DialogTitle>Assiger questionnaire</DialogTitle>
            <DialogContent>
              <Grid>
                <Typography className="form-control">Fiche *</Typography>
                <AutocompleteCombobox
                  onChange={handleChangeFiche}
                  label={"Fiche *"}
                  limitTags={2}
                  fullWidth
                  data={adaptDataValueToAutocompleteData(
                    fiches,
                    "LIBELE_FICHE",
                    "ID_FICHE_EVALUATION"
                  )}
                />
                <Typography className="form-control">
                  Catégorie critere 
                </Typography>
                <AutocompleteCombobox
                  limitTags={2}
                  fullWidth
                  label="Catégorie critere"
                  data={adaptDataValueToAutocompleteData(
                    categories,
                    "CATEGORY_NAME",
                    "ID_CATEGORY"
                  )}
                  onChange={handleChangeCategoryCritere}
                />
              </Grid>
              <Grid mt={1.5}>
                <Divider variant="fullWidth" />
                <Grid
                  flexGrow={1}
                  mb={1}
                  display={"flex"}
                  flexDirection={"row"}
                >
                  <Grid flexGrow={1}>
                    <CardHeader
                      title={"Liste des questions"}
                      sx={{ mb: -1 }}
                      titleTypographyProps={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    />
                  </Grid>
                  <Grid display={"flex"} alignItems={"end"}>
                    <TextField
                      size="small"
                      placeholder="Recherche"
                      onChange={(e) => setMotCle(e.target.value)}
                    />
                    <Button onClick={(e) => handleLoadAllData()}>
                      <RefreshOutlined />
                    </Button>
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" />
                <Grid maxHeight={"25vh"} overflow={"auto"} width={"100%"}>
                  {questions
                    .filter((item) => String(item.QUESTION).includes(motCle))
                    .map((item, idx) => (
                      <Paper elevation={1} sx={{ m: 0.5 }} key={idx}>
                        <Grid
                          p={1.5}
                          m={0.5}
                          display={"flex"}
                          flexDirection={"row"}
                        >
                          <Grid display={"flex"} flexGrow={1}>
                            <Typography>{item.QUESTION}</Typography>
                          </Grid>
                          <Grid display={"flex"} alignContent={"center"}>
                            <Checkbox
                              disabled={!filtre.FICHE}
                              checked={item.ID_FICHE_EVALUATION}
                              onChange={(e) => handleAssignQuestion(e, item)}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={!filtre.FICHE}
                variant="contained"
                color="primary"
                onClick={(e) => handleSaveAssignedQuestionToFiche()}
              >
                Enregister
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCloseDialog}
              >
                Fermer
              </Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default AssignerCritereToFiche;
