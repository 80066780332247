import { DataGrid } from "@material-ui/data-grid";
import { LoadingButton } from "@mui/lab";
import { Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import EvaluationSaisonForm from "./EvaluationSaisonForm";
import { EditOutlined } from "@material-ui/icons";
import { format } from "date-fns";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { exportToExcel } from "../../../helpers/ExportHelpers";
import { evaluation_refonte_saison_service } from "../../../services/PersonalEvaluationRefonteService";

const EvaluationSaisonTable = ({
  openForm,
  onCloseForm,
  handleOpenDialog,
  excelExport,
  filtre,
}) => {
  const [evaluationsSaisons, setEvaluationSaisonsList] = useState([]);
  const [formOpen, setFormOpen] = useState(openForm);
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);
  const [selected_saison, setSelectedSaison] = useState({
    ID_SAISON: 0,
    NOM_SESSION: "",
    DATE_DEBUT: format(new Date(), "yyyy-MM-dd"),
    DATE_FIN: format(new Date(), "yyyy-MM-dd"),
    CREATEUR: 0,
  });

  const handleOpenSaisonForm = () => {
    handleOpenDialog();
    setFormOpen(true);
  };

  const handleCloseFormDIalog = () => {
    onCloseForm();
    handleLoadDataTable();
    setFormOpen(false);
  };

  const handleEditSaison = (saison) => {
    setSelectedSaison(saison);
    handleOpenSaisonForm();
  };

  const handleLoadDataTable = () => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_refonte_saison_service.getList(filtre).then((res) => {
      setOpenLoadingSimpleBackdrop(false);
      setEvaluationSaisonsList(res.data || []);
    });
  };

  useEffect(() => {
    if (filtre) {
      handleLoadDataTable();
    }
    if (openForm) {
      setFormOpen(openForm);
    }

    if (excelExport > 0) {
      exportToExcel(
        evaluationsSaisons,
        columns,
        "Liste des saison " + new Date()
      );
    }
  }, [filtre, openForm, excelExport]);

  const columns = [
    {
      field: "SAISON_NAME",
      headerName: "Nom de la saison",
      width: 350,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "IS_PERSONALIZED",
      headerName: "Type de la saison",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value === "0" ? "Standard" : "Personnalisée"}>
          <Typography>
            {params.value === "0" ? "Standard" : "Personnalisée"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "ACTIVE",
      headerName: "Active",
      width: 130,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value === "1" ? "oui" : "non"}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "libelle_actions",
      headerName: "Actions",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            {/* <Tooltip title={"Cliquer pour afficher "}>
              <LoadingButton
                onClick={(e) => handleShowSaisonDetail(params.row)}
                color="primary"
              >
                <RemoveRedEyeOutlined />
              </LoadingButton>
            </Tooltip> */}
            <Tooltip title={"Cliquer pour Modifier "}>
              <LoadingButton
                onClick={(e) => handleEditSaison(params.row)}
                color="secondary"
              >
                <EditOutlined />
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 200,
    },
  ];
  return (
    <>
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <EvaluationSaisonForm
        isOpen={formOpen}
        handleClose={handleCloseFormDIalog}
        current_saison={selected_saison}
      />
      <DataGrid
        rows={evaluationsSaisons}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
        getRowId={(row) => row.ID_SAISON}
      />
    </>
  );
};

export default EvaluationSaisonTable;
