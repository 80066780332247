import { RefreshOutlined } from "@material-ui/icons";
import { DownloadOutlined } from "@mui/icons-material";
import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import EvaluationSaisonTable from "./EvaluationSaisonTable";

const EvaluationSaisonFiltre = ({
  handleOpenFormDialog,
  handleCloseFormDialog,
  openForm,
}) => {
  const [filtre, setFiltre] = useState({
    mot_cle: "",
    refresh: 0,
  });

  const [excelExport, declenceExportToExcel] = useState(0);

  const downloadExcel = () => {
    declenceExportToExcel(excelExport + 1);
  };

  const handleOpenDialogForm = () => {
    handleOpenFormDialog();
  };

  const handleCloseDialogForm = () => {
    handleCloseFormDialog();
  };

  const handleChangeCritere = (new_critere) => {
    setFiltre({ ...filtre, critere: new_critere });
  };

  const handleRefreshData = () => {
    setFiltre({ ...filtre, refresh: filtre.mot_cle + 1 });
  };

  const handlechangeFiltre = (evt) => {
    const { name, value } = evt.target;
    setFiltre({ ...filtre, [name]: value });
  };

  useEffect(() => {}, [filtre]);

  return (
    <Grid display={"flex"} flexDirection={"column"} height={"70vh"}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <TextField
          sx={{ width: 400 }}
          size="small"
          name="mot_cle"
          value={filtre.mot_cle}
          label="Mot clé"
          onChange={handlechangeFiltre}
          className="filtre_list"
        />
        <Button
          variant="outlined"
          className="filtre_list"
          onClick={handleRefreshData}
        >
          <RefreshOutlined />
        </Button>
        <Button
          variant="outlined"
          className="filtre_list"
          onClick={downloadExcel}
        >
          <DownloadOutlined /> Excel export
        </Button>
      </Grid>
      <Grid flexGrow={1} mt={3}>
        <EvaluationSaisonTable
          excelExport={excelExport}
          handleOpenDialog={handleOpenDialogForm}
          onCloseForm={handleCloseDialogForm}
          openForm={openForm}
          filtre={filtre}
        />
      </Grid>
    </Grid>
  );
};

export default EvaluationSaisonFiltre;
