import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from '@mui/icons-material/Search';
import DateRangeTable from './DateRangeTable'; 
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import PlanningMissionService from "../../../services/PlanningMissionService";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Message from "../../tools/Message";
import TableViewIcon from '@mui/icons-material/TableView';
import ExcelJS from 'exceljs';


function MissionList(props) {
    const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] = React.useState(false);
    const [filter, setFilter] = useState({
        date_debut: '',
        date_fin: '',
        direction: [],
        code_mission:'',
        destination:'',
        status:[],
        ligne:"missionaire",
        valeur:"direction"
    });
    const [message, setMessage] = React.useState({
        open: false,
        color: "green",
        messageInfo: "",
      });
    const { open, color, messageInfo } = message;
    const [valdir,setValdir]=useState([]);
    const [direction, setDirection] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [datatable,setDatatable]=useState([]);
    const options = [
        { id: 'En attente de validation', label: 'En attente de validation' },
        { id: "validé", label: 'Validé DG' },
        { id: 'Refusé', label: 'Refusé DG' },
    ];

    const optionscolone=[
        {id:"missionaire",label:"Missionaires"},
        {id:"destination",label:"Destination"},
        {id:"direction",label:"Direction de destination"},
        {id:"codemission",label:"Code mission"}
    ];

    const optionsvaleur=[
        {id:"destination",label:"Destination"},
        {id:"direction",label:"Direction de destination"},
        {id:"nbrmissionaire",label:"Nombre missionaires"}
    ];


    function handleChange(event) {
        const { name, value } = event.target;
        setFilter({ ...filter, [name]: value }); 
    }

    function handleChangeligne(event,newvalue) {
       setFilter({ ...filter, ligne: newvalue.id });   
    }

    function handleChangevaleur(event,newvalue) {    
      setFilter({ ...filter, valeur: newvalue.id });    
     }

    function handleChangeDirdestination(event, value) {
        setValdir(value);
        const iddir = value.map(item => item.direction_id); 
        setFilter({ ...filter, direction: iddir });
    }

    const generateDateRange = (debut,fin) => {
        const startDate=new Date(debut);
        const endDate=new Date(fin);
        const dates = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const groupDaysByMonth = (dates) => {
        const months = {};
        dates.forEach(date => {
            const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 0).padStart(1, '0')}`;
            if (!months[monthKey]) {
                months[monthKey] = [];
            }
            months[monthKey].push(date.getDate());
        });
        
        return months;
    };

    const handleExportExcel = async () => {
                const dates = generateDateRange(filter.date_debut, filter.date_fin);
                const monthsGrouped = groupDaysByMonth(dates);
                const tete=[];
                tete.push({ header: 'Nom et prénom(s)', key: 'name', width: 40 });
                
                Object.keys(monthsGrouped).forEach(monthKey => {
                    const [year, month] = monthKey.split('-'); 
                    console.log("Year:", year, "Month:", month);
                    
                    const monthDays = monthsGrouped[monthKey];
                    
                    monthDays.forEach(day => {
                        const list=day+'/'+month;
                        tete.push({ header: list, key: day, width: 8 });  
                    });
                });
    
                
                const monthKeys = Object.keys(monthsGrouped).sort((a, b) => {
                    const [yearA, monthA] = a.split('-').map(Number);
                    const [yearB, monthB] = b.split('-').map(Number);
                    return yearA === yearB ? monthA - monthB : yearA - yearB;
                });
    
                const data = [];
                const datacolor=[];
    
                datatable.forEach((ts, index) => {
                    const hasHighlight = monthKeys.some((monthKey) =>
                        monthsGrouped[monthKey].some((day) =>
                            day >= ts.debut && day <= ts.fin && parseInt(ts.moiss, 10) === parseInt(monthKey.split('-')[1], 10)
                        )
                    );
                    
                    if (hasHighlight) {
                        let i = index;
                        let rowData = [];  
                        let rowcolor=[];
                
                        rowData[0] = ts.ligne; 
                        rowcolor[0]='FFFFFFFF';
                        
                        let j = 1;
                        monthKeys.forEach((monthKey) => {
                            monthsGrouped[monthKey].forEach((day) => {
                                if (parseInt(ts.moiss, 10) === parseInt(monthKey.split('-')[1], 10)) {
                                    if (day >= ts.debut && day <= ts.fin) {
                                        const color =
                                        ts.state === 'En attente de validation' ? 'FF1414B8' :
                                        ts.state === 'Planifié' ? 'FF78B785' :
                                        ts.state === 'Nouveau' ? 'FF87CEEB' : 'FFFF0000';
    
                                        rowData[j] = ts.valeur;
                                        rowcolor[j]= color;
    
                                    } else {
                                        rowData[j] = ''; 
                                        rowcolor[j] ='FFFFFFFF';
                                    }
                                }
                                j++;
                            });
                        });
    
                        if (rowData.length > 1) {  
                            data.push(rowData);
                        }
                        if (rowcolor.length>1) {
                            datacolor.push(rowcolor);
                        } 
                    }
                });
              
            const dataWithHeader = data;
            
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet1');
        
            // Définir les colonnes
            worksheet.columns =tete;
        
            // Ajouter les données
            for (let index = 0; index < dataWithHeader.length; index++) {
                const row = worksheet.addRow(dataWithHeader[index]);
            
                if (datacolor[index]) {
                    row.eachCell({ includeEmpty: true }, (cell, colIndex) => {
                        const color = datacolor[index][colIndex - 1];
            
                        if (color !== 'FFFFFFFF' && color!==undefined) {
                            console.log("color",color);
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: color }
                            };
                        }
                    });
                }
            }
            
     
        
            worksheet.getRow(1).font = { bold: true, color: { argb: 'FFFFFF' } };
            worksheet.getRow(1).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'ADD8E6' }
            };
        
    
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'planning.xlsx';
            link.click();
        };

     function handleExportPDF() {
           const input = document.getElementById('table-to-pdf');
            html2canvas(input).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 190; 
                const pageHeight = pdf.internal.pageSize.height;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const heightLeft = imgHeight;

                let position = 20; 


                pdf.setFontSize(20); 
                pdf.text('Liste des missions', 10, 10); 

                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                position += imgHeight;

                if (heightLeft >= pageHeight) {
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                }

                pdf.save('table.pdf');
            });
    }

    function handleFilter() {
        if (filter.ligne===filter.valeur) {
            setMessage({ ...message, open: true, color: 'red', messageInfo: "La valeur dans la ligne ne peut pas être identique à la valeur." });
        }

        PlanningMissionService.getEtat(filter).then((result) => {
            if (result) {
                setShowTable(true);
                setDatatable(result.data);     
                if (result.data.length === 0) {
                    setMessage({ ...message, open: true, color: 'red',messageInfo: "Aucune donnée disponible pour le filtre sélectionné. Veuillez réinitialiser" });
                }
            }
            else{
                setShowTable(false);
                setMessage({ ...message, open: true, color: 'red', messageInfo:result.message });
            }
          });
    }

    const handleChangestatus = (event, newValue) => {
        setSelectedOptions(newValue);
        const id = newValue.map(item => item.id); 
        setFilter({ ...filter, status: id });
    };

    function handlereinitiale()
    {
      window.location.reload();
    }

    function handleCloseMessage() {
        setMessage({ ...message, open: false });
      }

    useEffect(() => {
        PlanningMissionService.getDirection().then((result) => {
            if (result && result.data) {
                setDirection(result.data);
            }
        });
    }, []);


    return (
        <>
            <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
            <LoadingSimpleBackdrop openLoadingSimpleBackdrop={openLoadingSimpleBackdrop} />
            <Grid container spacing={4}>
               <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFilter} 
                        style={{ fontWeight: 600, width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <SearchIcon style={{ marginRight: '8px' }} />
                        Filtrer
                    </Button>
                </Grid>  
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handlereinitiale} 
                        style={{ fontWeight: 600, width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                    <RestartAltIcon style={{ marginRight: '8px' }} />
                        réinitialiser
                    </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                        variant="contained"
                        color="#9E9E9E "
                        onClick={handleExportPDF} 
                        style={{ fontWeight: 600, width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <PictureAsPdfIcon style={{ marginRight: '8px' }} />
                        Export pdf
                    </Button>
                </Grid>
                
                <Grid item xs={2}>
                   <Button
                        variant="contained"
                        color="#9E9E9E"
                        onClick={handleExportExcel} 
                        style={{ fontWeight: 600, width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <TableViewIcon style={{ marginRight: '8px' }} />
                        Export excel
                    </Button>
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={3}>
                     Date début:
                    <TextField
                        fullWidth
                        id="date_debut"
                        size="small"
                        variant="outlined"
                        type="date"
                        value={filter.date_debut}
                        name="date_debut"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    Direction destination :
                    <Autocomplete
                        onChange={handleChangeDirdestination}
                        multiple
                        value={valdir}
                        name="direction"
                        options={direction}
                        getOptionLabel={(option) => `${option.name} (${option.code})`}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                size="small"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    Code mission :
                    <TextField
                        fullWidth
                        id="code_mission"
                        size="small"
                        variant="outlined"
                        value={filter.code_mission}
                        name="code_mission"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                        Ligne :
                        <Autocomplete
                            onChange={handleChangeligne}
                            name="ligne"
                            options={optionscolone}
                            getOptionLabel={(option) => `${option.label}`}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                />
                            )}
                       />
                </Grid>
                <Grid item xs={3}>
                     Date fin:
                    <TextField
                        fullWidth
                        id="date_fin"
                        size="small"
                        variant="outlined"
                        type="date"
                        value={filter.date_fin}
                        name="date_fin"
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                     Destination :
                    <TextField
                        fullWidth
                        id="destination"
                        size="small"
                        variant="outlined"
                        value={filter.destination}
                        name="destination"
                        onChange={handleChange}
                    />
                </Grid>
               
                <Grid item xs={3}> 
                        Statut
                         <Autocomplete
                            onChange={handleChangestatus}
                            multiple
                            //value={filter.direction}
                            name="status"
                            options={options}
                            getOptionLabel={(option) => `${option.label}`}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                />
                            )}
                       />
                </Grid>
                <Grid item xs={3}> 
                        Valeur :
                        <Autocomplete
                            onChange={handleChangevaleur}
                            name="valeur"
                            options={optionsvaleur}
                            getOptionLabel={(option) => `${option.label}`}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                />
                            )}
                       />
                </Grid>
                <Grid item xs={3}>
                    <Box
                        component="span"
                        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: '#87CEEB' }}
                    >
                    </Box>
                    Mission planifié
                </Grid>
                
                <Grid item xs={3}>
                    <Box
                        component="span"
                        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: '#1414b8' }}
                    >
                    </Box>
                    Mission en attente de validation 
                </Grid>
                <Grid item xs={3}>
                    <Box
                        component="span"
                        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: '#78b785' }}
                    >
                    </Box>
                    Mission validé 
                </Grid>
                <Grid item xs={3}>
                    <Box
                        component="span"
                        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', width: '15px', height: '15px', background: 'red' }}
                    >
                    </Box>
                    Mission refusé
                </Grid>
           <Grid item xs={12}>
                    {showTable && filter.date_debut && filter.date_fin && (
                        <DateRangeTable
                            startDate={new Date(filter.date_debut)}
                            endDate={new Date(filter.date_fin)}
                            datatable={datatable}
                        />
                    )}
            </Grid>
           
          </Grid>
        </>
    );
}

export default MissionList;
