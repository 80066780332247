import { Paper } from "@material-ui/core";
import { Add, ArrowBack } from "@material-ui/icons";
import { Button, Grid, Typography } from "@mui/material";
import "../shared/style/list_style.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";

const AppPageForTableList = ({
  handleClickBtnCreer,
  button_creer_title,
  title,
  filter_component,
  table_component,
  show_buton_back,
  hide_buton_creer,
  url_back,
}) => {
  const [formOpen, setFormOpen] = useState(false);
  const navigate = useNavigate();
  const handleClickCreerBtn = () => {
    handleClickBtnCreer();
  };

  const handleBackButtonsClick = (e) => {
    url_back ? navigate(url_back) : window.history.back();
  };
  return (
    <Paper>
      <Grid
        p={"20px 20px"}
        display={"flex"}
        flexDirection={"column"}
        height={"83vh"}
      >
        <Grid display={"flex"} flexDirection={"row"}>
          <Grid>
            <Button
              sx={{ display: !show_buton_back ? "none" : "" }}
              color="primary"
              className="filtre_list"
              onClick={(e) => handleBackButtonsClick(e)}
            >
              <ArrowBack />
            </Button>
            <Button
              sx={{ display: hide_buton_creer ? "none" : "" }}
              onClick={handleClickCreerBtn}
              variant="contained"
              color="error"
              size="small"
            >
              {button_creer_title || "Creer"}
            </Button>
          </Grid>
          <Grid
            display={"flex"}
            justifyContent={"center"}
            textAlign={"center"}
            flexGrow={1}
            flexWrap={""}
          >
            <Typography variant="h6" color={"#1a73e8"}>
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid flexGrow={1}>{filter_component}</Grid>
        {/*  <Grid flexGrow={1}>
          {table_component}
        </Grid> */}
      </Grid>
    </Paper>
  );
};

export default AppPageForTableList;
