import {
  Alert,
  AlertTitle,
  CardContent,
  CardHeader,
  Grid,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { LoadingButton } from "@mui/lab";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import EvaluationExaminationForm from "./EvaluationExaminationForm";
import { evaluation_questionnaire_service } from "../../../services/PersonalEvaluationService";
import EvaluationExaminationFormResume from "./EvaluationExaminationFormResume";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { evaluation_refonte_fiche_evaluation_service } from "../../../services/PersonalEvaluationRefonteService";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";

const EvaluationForm = () => {
  const [current_user, setCurrentUser] = useState(null);
  const [open_form, setOpenForm] = useState(false);
  const bonjour = new Date().getHours() < 19 ? "Bonjour " : "Bonsoir ";
  const [texte_salutaion, setTextSalutation] = useState(bonjour);
  const [formResumeOpen, setFormResumeOpen] = useState(false);
  const [question_response_by_user, setQuestionReponseByUser] = useState([]);
  const [openLoadingSimpleBackdrop, setopenLoadingSimpleBackdrop] =
    useState(false);

  const refreshCurentUser = () => {
    UserService.myCompleteUserInfoForEvaluation().then((res) => {
      if (res) {
        setCurrentUser(res.data);
        checkIfEvaluationAlreadyDone(res.data.user_id);
        setTextSalutation(
          `${bonjour} ${res.data.last_name || ""} ${res.data.first_name || ""}`
        );
      }
    });
  };

  const checkIfEvaluationAlreadyDone = (candidat_id) => {
    evaluation_refonte_fiche_evaluation_service
      .getAllDetailByUserId(candidat_id)
      .then((res) => {
        if (res) {
          setQuestionReponseByUser(res.data);
        }
      });
  };

  const onCloseForm = () => {
    setOpenForm(false);
    refreshCurentUser();
  };

  const handleCLickStartEvaluation = () => {
    setOpenForm(true);
  };

  const handleCloseFormResumeDIalog = () => {
    onCloseForm();
    setFormResumeOpen(false);
  };

  const handleShowEvaluationResume = () => {
    setFormResumeOpen(true);
  };

  useEffect(() => {
    refreshCurentUser();
  }, []);

  return (
    <>
      <EvaluationExaminationFormResume
        selected_employee={current_user}
        isOpen={formResumeOpen}
        handleClose={handleCloseFormResumeDIalog}
      />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <EvaluationExaminationForm
        candidat={current_user}
        isOpen={open_form}
        handleClose={onCloseForm}
      />
      <Paper sx={{ borderTop: "5px solid #00a452" }}>
        {question_response_by_user.length > 0 ? (
          <Grid>
            <CardHeader
              title={
                <div>
                  {texte_salutaion}, <br />
                  {question_response_by_user.some(
                    (item) => item.TERMINEE === "1"
                  )
                    ? "Vous avez déjà fais votre auto-evaluation, vous pouvez à tous moment modifier vos reponse sauf si vous avez terminé et envoyé les données enregistrées."
                    : "La première phase de l'evaluation du personnel est votre auto-évaluation. Vous allez juste glisser le slider par rapport au note que vous estimez sur la question posée."}
                </div>
              }
              titleTypographyProps={{
                variant: "h6",
                /* color: 'primary', */
                gutterBottom: true,
              }}
            />
            <CardContent>
              <Grid textAlign={"center"}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={!current_user}
                  startIcon={<PsychologyAltOutlinedIcon />}
                  onClick={handleCLickStartEvaluation}
                >
                  {question_response_by_user.some(
                    (item) => item.TERMINEE === "1"
                  )
                    ? "Afficher evaluation"
                    : "Commencer"}
                </LoadingButton>
                <LoadingButton
                  sx={{ ml: 1, display: "none" }}
                  variant="outlined"
                  color="secondary"
                  disabled={!current_user}
                  startIcon={<RemoveRedEyeOutlined />}
                  onClick={handleShowEvaluationResume}
                >
                  Afficher resumé
                </LoadingButton>
              </Grid>
            </CardContent>
          </Grid>
        ) : (
          <Grid>
            <Alert severity="error">
              <AlertTitle>Questionnaire non trouvé</AlertTitle>
              Aucune question disponible pour vous evaluer  (raison :
              aucune session ou fiche d'evaluation active ). <br/>Veuillez contacter
              le responsable de l'evaluation du personnel
            </Alert>
          </Grid>
        )}
      </Paper>
    </>
  );
};

export default EvaluationForm;
