import { useEffect, useRef, useState } from "react";
import { evaluation_generale_service, evaluation_questionnaire_service } from "../../../services/PersonalEvaluationService";
import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { CardContent } from "@material-ui/core";
import useToast from "../../../hooks/useToast";
import { useReactToPrint } from "react-to-print";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const TYPE_REPONSE = {
  comment: { id: 0, name: "Commentaire" },
  texte_libre: { id: 1, name: "Reponse libre" },
  note: { id: 2, name: "En note" },
  jalon: { id: 3, name: "En jalon" },
};

export const findOutEvaluationGenerale = (note_total, note_max_total) => {
  let moyenne_generale = note_total / note_max_total;

  if (moyenne_generale <= 20 / 50) {
    return "Faible";
    //return { couleur: "#a00", valeur: "Faible" };
  } else if (moyenne_generale > 20 / 50 && moyenne_generale < 28 / 50) {
    return "A améliorer";
    //return { couleur: "#a50", valeur: "A améliorer" };
  } else if (moyenne_generale > 27 / 50 && moyenne_generale < 36 / 50) {
    return "Satisfaisant";
    //return { couleur: "#aa0", valeur: "Satisfaisant" };
  } else if (moyenne_generale > 35 / 50 && moyenne_generale < 44 / 50) {
    return "Superieur";
    //return { couleur: "#00a", valeur: "Superieur" };
  } else if (moyenne_generale > 43 / 50) {
    return "Exceptionnel";
    //return { couleur: "#080", valeur: "Exceptionnel" };
  }
};

const EvaluationExaminationFormResume = ({
  isOpen,
  selected_employee,
  handleClose,
  titre,
}) => {
  const componentRef = useRef();
  const initial_person = {
    user_id: 0,
    last_name: "",
    first_name: "",
    email: "",
    function_name: "",
    function_id: 0,
    direction_id: 0,
    direction_code: "",
    service_code: "",
    service_id: 0,
    registration_number: "",
  };
  const [selected_person, setSelectedPerson] = useState(initial_person);
  const [questions, setQuestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const { showToast, Toast } = useToast();
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);
  const default_evaluation_generale = {
    ID_SAISON_CANDIDAT: 0,
    ID_SAISON: 0,
    COMMENTAIRE_CONSULTANT_INTERVUE: "",
    POINT_FORT: "",
    POINT_A_AMELIORER: "",
    EVALUATION_GENERALE: "",
    ACTION_A_MENER: "",
    RESULTAT_GENERAL: "",
    NOTE_GENERAL: 0,
  };
  const [evaluation_generale, setEvaluationGenerale] = useState(
    default_evaluation_generale
  );

  useEffect(() => {
    if (selected_employee || isOpen) {
      setSelectedPerson(selected_employee);
    }
    if (isOpen) {
      handleRefreshQuestionsLIst(selected_employee.user_id);
    }
  }, [selected_employee, isOpen]);

  const handleFechEvaluationGenerale = (id_saison, id_candidat) => {
    evaluation_generale_service
      .getEvaluaitonGeneraleByCadidatAndSaison({
        id_saison: id_saison,
        id_candidat: id_candidat,
        //id_evaluateur: id_evaluateur,
      })
      .then((res) => {
        if (res && res.success) {
          setEvaluationGenerale(
            res.data || {
              ID_SAISON_CANDIDAT: 0,
              ID_CANDIDAT: id_candidat,
              ID_SAISON: id_saison,
              COMMENTAIRE_CONSULTANT_INTERVUE: "",
              POINT_FORT: "",
              POINT_A_AMELIORER: "",
              EVALUATION_GENERALE: "",
              ACTION_A_MENER: "",
              RESULTAT_GENERAL: "",
              NOTE_GENERAL: 0,
            }
          );
        }
      })
      .catch((e) => {
        showToast(TYPE_NOTIFICATION.ERROR, e.toString());
      });
  };

  const handleRefreshQuestionsLIst = (user_id) => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_questionnaire_service
      .getQuestionnaireListByuserIdForResume(user_id)
      .then((res) => {
        if (res) {
          if (res && res.success) {
            setQuestions(res.data);
            setCategories(
              Array.from(new Set(res.data.map((item) => item.NOM_CATEGORY)))
            );
            handleFechEvaluationGenerale(res.data[0].ID_SAISON || 0, user_id);
          }
          setOpenLoadingSimpleBackdrop(false);
        }
      })
      .finally(() => {
        setOpenLoadingSimpleBackdrop(false);
      });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const initAll = () => {
    setQuestions([]);
    setCategories([]);
    setSelectedPerson(initial_person);
  };

  const handleDialogClose = () => {
    initAll();
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      /* onClose={ handleDialogClose} */
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullScreen
      maxWidth="xl"
    >
      <Toast />
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <Paper>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          height={"100vh"}
          bgcolor={"#f9f9f9"}
        >
          <DialogContent
            id="dialog-description"
            className="app-scroll-bar-view"
            sx={{ overflow: "auto", maxHeight: "100%" }}
          >
            <Grid item mt={0.5} display={"flex"} flexDirection={"column"}>
              <Paper
                elevation={1}
                sx={{ mb: 1, borderTop: "5px solid #00a452" }}
              >
                <Grid className="info-personnelle">
                  <Grid ref={componentRef}>
                    <Grid>
                      <Grid display={"flex"} flexDirection={"row"}>
                        <Grid item xs={6} flexGrow={1}>
                          <CardHeader
                            title={"Information du candidat"}
                            titleTypographyProps={{
                              variant: "h6",
                              color: "primary",
                              gutterBottom: true,
                            }}
                            sx={{ mb: -2 }}
                          />
                          <Typography
                            variant="body1"
                            component={"div"}
                            sx={{ margin: "20px 30px" }}
                          >
                            Nom et prénoms :{" "}
                            {selected_person.last_name +
                              " " +
                              selected_person.first_name}{" "}
                            <br />
                            Fonction : {selected_person.function_name}
                            <br />
                            Direction: {selected_person.direction_code}
                            <br />
                            Service: {selected_person.service_code}
                            <br />
                          </Typography>
                        </Grid>
                        <Grid item xs={6} flexGrow={1}></Grid>
                      </Grid>
                      <Grid>
                        <CardHeader
                          title="Resumé de l'évaluation du candidat"
                          titleTypographyProps={{
                            textAlign: "center",
                            variant: "h5",
                            color: "primary",
                            gutterBottom: true,
                          }}
                          sx={{ mb: -2 }}
                        />
                        <CardContent>
                          {categories.length > 0
                            ? categories.map((cat, index) => (
                                <Grid>
                                  <CardHeader
                                    title={`Section ${index + 1}: ${cat}`}
                                    titleTypographyProps={{
                                      variant: "h6",
                                      color: "primary",
                                      gutterBottom: true,
                                    }}
                                    sx={{ mb: -2 }}
                                  />

                                  <Grid>
                                    <table style={{ width: "100%" }} border="1">
                                      <tr>
                                        <th style={{ minWidth: "30%" }}>
                                          Critère
                                        </th>
                                        <th>Reponse/note (Auto-evaluation) </th>
                                        <th>
                                          Reponse/note (Evaluation du superieur){" "}
                                        </th>
                                      </tr>

                                      {questions
                                        .filter((q) => q.NOM_CATEGORY === cat)
                                        .map((qs, idx) => (
                                          <tr>
                                            <td>
                                              {`${idx + 1}. ${qs.QUESTION}`}
                                            </td>
                                            <td>
                                              {qs.NOM_TYPE ===
                                              TYPE_REPONSE.note.name
                                                ? `Note: ${Number(
                                                    qs.NOTE
                                                  )}/${Number(qs.NOTE_MAX)}`
                                                : `Reponse: ${qs.REPONSE}`}
                                            </td>
                                            <td>
                                              {(qs.NOTE_S || qs.REPONSE_S) &&
                                                (qs.NOM_TYPE ===
                                                TYPE_REPONSE.note.name
                                                  ? `Note: ${Number(
                                                      qs.NOTE_S
                                                    )}/${Number(qs.NOTE_MAX)}`
                                                  : `Reponse: ${qs.REPONSE_S}`)}
                                            </td>
                                          </tr>
                                        ))}
                                      {questions
                                        .filter((q) => q.NOM_CATEGORY === cat)
                                        .map(
                                          (qs, idx) =>
                                            idx === 0 && (
                                              <tr>
                                                <td>Note total</td>
                                                <td>
                                                  {qs.NOM_TYPE ===
                                                  TYPE_REPONSE.note.name
                                                    ? questions
                                                        .filter(
                                                          (q) =>
                                                            q.NOM_CATEGORY ===
                                                            cat
                                                        )
                                                        .reduce(
                                                          (somme, qs) =>
                                                            somme +
                                                            Number(qs.NOTE),
                                                          0
                                                        ) +
                                                      "/" +
                                                      questions
                                                        .filter(
                                                          (q) =>
                                                            q.NOM_CATEGORY ===
                                                            cat
                                                        )
                                                        .reduce(
                                                          (somme, qs) =>
                                                            somme +
                                                            Number(
                                                              qs.NOTE_MAX || 0
                                                            ),
                                                          0
                                                        )
                                                    : ``}
                                                </td>
                                                <td>
                                                  {qs.NOM_TYPE ===
                                                  TYPE_REPONSE.note.name
                                                    ? questions
                                                        .filter(
                                                          (q) =>
                                                            q.NOM_CATEGORY ===
                                                            cat
                                                        )
                                                        .reduce(
                                                          (somme, qs) =>
                                                            somme +
                                                            Number(qs.NOTE_S),
                                                          0
                                                        ) +
                                                      "/" +
                                                      questions
                                                        .filter(
                                                          (q) =>
                                                            q.NOM_CATEGORY ===
                                                            cat
                                                        )
                                                        .reduce(
                                                          (somme, qs) =>
                                                            somme +
                                                            Number(
                                                              qs.NOTE_MAX || 0
                                                            ),
                                                          0
                                                        )
                                                    : ``}
                                                </td>
                                              </tr>
                                            )
                                        )}
                                    </table>
                                  </Grid>
                                </Grid>
                              ))
                            : ""}
                          <Grid>
                            <CardHeader
                              title={`Section ${
                                categories.length + 1
                              } : Le niveau du rendement du Consultant est la somme des évaluations des Sections`}
                              titleTypographyProps={{
                                variant: "h6",
                                color: "primary",
                                gutterBottom: true,
                              }}
                              sx={{ mb: -2 }}
                            />
                            <table
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              border="1"
                            >
                              {categories.length > 0
                                ? categories.map((cat, index) => (
                                    <tr>
                                      <td>{`Section ${index + 1}. ${cat}`}</td>
                                      <td>
                                        {questions
                                          .filter(
                                            (item) => item.NOM_CATEGORY === cat
                                          )
                                          .reduce(
                                            (somme, q) =>
                                              somme +
                                              Number(q.NOTE_S || q.NOTE),
                                            0
                                          ) +
                                          " sur " +
                                          questions
                                            .filter(
                                              (item) =>
                                                item.NOM_CATEGORY === cat
                                            )
                                            .reduce(
                                              (somme, q) =>
                                                somme + Number(q.NOTE_MAX),
                                              0
                                            )}
                                      </td>
                                    </tr>
                                  ))
                                : ""}
                              <tr>
                                <td style={{ textAlign: "right" }}>
                                  Total des critères d’évaluation du Consultant
                                </td>
                                <td>
                                  {questions.reduce(
                                    (somme, q) =>
                                      somme + Number(q.NOTE_S || q.NOTE),
                                    0
                                  ) +
                                    " sur " +
                                    questions.reduce(
                                      (somme, q) => somme + Number(q.NOTE_MAX),
                                      0
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "right" }}>
                                  L'appréciation générale du Consultant est la
                                  somme de :
                                </td>
                                <td>
                                  {questions.reduce(
                                    (somme, q) =>
                                      somme + Number(q.NOTE_S || q.NOTE),
                                    0
                                  ) +
                                    " sur " +
                                    questions.reduce(
                                      (somme, q) => somme + Number(q.NOTE_MAX),
                                      0
                                    )}
                                </td>
                              </tr>
                            </table>
                          </Grid>
                          <Grid className="classification-finale">
                            <CardHeader
                              title={`Section ${
                                categories.length + 2
                              } : Classification finale`}
                              titleTypographyProps={{
                                variant: "h6",
                                color: "primary",
                                gutterBottom: true,
                              }}
                              sx={{ mb: -2 }}
                            />
                            <Grid display="flex" justifyContent={"center"}>
                              <Grid>
                                <Paper>
                                  <Grid p={1}>
                                    <Typography variant="h6">
                                      {`Note: ${questions.reduce(
                                        (somme, qs) =>
                                          somme + Number(qs.NOTE_S || qs.NOTE),
                                        0
                                      )} / ${questions.reduce(
                                        (somme, qs) =>
                                          somme + Number(qs.NOTE_MAX),
                                        0
                                      )}`}
                                    </Typography>
                                  </Grid>
                                </Paper>
                              </Grid>
                              <Grid ml={1}>
                                <Paper>
                                  <Grid p={1}>
                                    <Typography
                                      variant="h6"
                                      //color={findOutEvaluationGenerale().color}
                                    >
                                      {findOutEvaluationGenerale(
                                        questions.reduce(
                                          (somme, qs) =>
                                            somme +
                                            Number(qs.NOTE_S || qs.NOTE),
                                          0
                                        ),
                                        questions.reduce(
                                          (somme, qs) =>
                                            somme + Number(qs.NOTE_MAX),
                                          0
                                        )
                                      )}
                                    </Typography>
                                  </Grid>
                                </Paper>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid className="evaluation-generale">
                            <CardHeader
                              title={`Section ${
                                categories.length + 3
                              } : Commentaires du consultant(e) sur l'entrevue`}
                              titleTypographyProps={{
                                variant: "h6",
                                color: "primary",
                                gutterBottom: true,
                              }}
                              sx={{ mb: -2 }}
                            />
                            <table
                              style={{
                                width: "100%",
                                marginTop: "10px",
                                minHeight: "90px",
                              }}
                              border="1"
                            >
                              <tr>
                                <td>
                                  {evaluation_generale.COMMENTAIRE_CONSULTANT_INTERVUE ||
                                    ""}
                                </td>
                              </tr>
                            </table>
                            <CardHeader
                              title={`Section ${
                                categories.length + 4
                              } : Commentaires généraux du Directeur et superviseur hiérarchique`}
                              titleTypographyProps={{
                                variant: "h6",
                                color: "primary",
                                gutterBottom: true,
                              }}
                              sx={{ mb: -2 }}
                            />
                            <table
                              style={{
                                width: "100%",
                                marginTop: "10px",
                                minHeight: "90px",
                              }}
                              border="1"
                            >
                              <tr>
                                <td>Points forts du consultant</td>
                                <td style={{ minWidth: "50%" }}>
                                  {evaluation_generale.POINT_FORT || ""}
                                </td>
                              </tr>
                              <tr>
                                <td>Points à améliorer</td>
                                <td style={{ minWidth: "50%" }}>
                                  {evaluation_generale.POINT_A_AMELIORER || ""}
                                </td>
                              </tr>
                              <tr>
                                <td>Évaluation générale</td>
                                <td style={{ minWidth: "50%" }}>
                                  {evaluation_generale.EVALUATION_GENERALE ||
                                    ""}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Actions à mener pour améliorer la performance
                                </td>
                                <td style={{ minWidth: "50vw" }}>
                                  {evaluation_generale.ACTION_A_MENER || ""}
                                </td>
                              </tr>
                            </table>
                          </Grid>
                        </CardContent>
                      </Grid>
                      <Grid display={"flex"} flexDirection={"row"}>
                        <Grid item fle></Grid>
                        <Grid></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    display={"flex"}
                    justifyContent={"center"}
                    m={5}
                    spacing={2}
                  ></Grid>
                </Grid>
              </Paper>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container display={"flex"} flexDirection={"row"}>
              <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                <Button
                  color="warning"
                  variant="outlined"
                  size="small"
                  endIcon={<CloseOutlined />}
                  onClick={handleDialogClose}
                >
                  Fermer
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Paper>
    </Dialog>
  );
};

export default EvaluationExaminationFormResume;
