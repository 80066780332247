import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";
import useConfirm from "../../../hooks/useConfirm";
import useConfirmation from "../../../hooks/useConfirmation";

const FicheDevaluation = ({ isOpen, current_critere_groupe, handleClose }) => {
  const [categories, setCategories] = useState([]);
  const { Toast, showToast } = useToast();
  const [questionnaires, setQuestionnaires] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const { DialogComponent, openConfirmDialog } = useConfirmation();

  const loadFicheDeEvaluation = () => {
    
  };

  const handleDialogClose = () => {
    handleClose();
  };

  const handleUpdateActiveStatus = (critere_groupe) => {
   
  };

  const handleChangeActiveFicheDePoste = (evt) => {
    setIsActive(evt.target.checked);
    let categ = current_critere_groupe;
    categ.ACTIVE = evt.target.checked ? "1" : "0";
    categ.UTILISE = "0";
    openConfirmDialog(
      "Confirmation",
      "Voulez vous utilisez ce questionnaire soit utilisé dans la saison activé? Les autres critere associé à ce poste serais enlevé",
      () => {
        categ.UTILISE = "1";
        handleUpdateActiveStatus(categ);
      },
      () => {
        categ.UTILISE = "0";
        handleUpdateActiveStatus(categ);
      }
    );
  };

  useEffect(() => {
    console.log('ficehc devaluation', current_critere_groupe);
    
    if (current_critere_groupe) {
      loadFicheDeEvaluation();
      setIsActive(current_critere_groupe.ACTIVE === "1");
    }
  }, [current_critere_groupe]);

  return (
    <>
      <Toast />
      <DialogComponent />
      <Dialog
        open={isOpen}
        onClose={handleDialogClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="md"
      >
        <Paper>
          <Grid>
            <DialogTitle
              id="dialog-title"
              className="entete-dialog"
              display={"flex"}
              flexDirection={"row"}
            >
              {/* {`Fiche d'evaluation : ${(current_critere_groupe.NOM_CRITERE_GROUPEE) ? current_critere_groupe.NOM_CRITERE_GROUPEE : ""}`} */}
              <Grid flexGrow={1}>{`Fiche d'evaluation : ${
                current_critere_groupe.NOM_CRITERE_GROUPEE || ""
              } `}</Grid>
              <Grid>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isActive}
                      onChange={handleChangeActiveFicheDePoste}
                      name="gilad"
                    />
                  }
                  label={isActive ? "Active" : "Inactive"}
                />
              </Grid>
            </DialogTitle>
            <DialogContent>
              {categories.map((category_name, idx) => (
                <>
                  <CardHeader
                    title={"Section " + (idx + 1) + ": " + category_name}
                    titleTypographyProps={{
                      variant: "h6",
                      color: "primary",
                      gutterBottom: true,
                    }}
                    sx={{ mb: -2 }}
                  />
                  <table style={{ width: "100%" }} border="1">
                    <tr>
                      <th>Criteres</th>
                      <th style={{ width: "15vw" }}>Type de reponse</th>
                    </tr>
                    {questionnaires
                      .filter(
                        (item, idx2) => item.NOM_CATEGORY === category_name
                      )
                      .map((qs, num) => (
                        <tr>
                          <td>{" " + (num + 1) + ". " + qs.QUESTION}</td>
                          <td>
                            {qs.NOM_TYPE === "En note"
                              ? `${qs.NOM_TYPE} de ${qs.NOTE_MIN} à ${qs.NOTE_MAX}`
                              : `${qs.NOM_TYPE}`}
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td
                        style={{ textAlign: "right" }}
                      >{`Total (de ${questionnaires
                        .filter(
                          (item, idx2) => item.NOM_CATEGORY === category_name
                        )
                        .reduce(
                          (somme, qs) => somme + Number(qs.NOTE_MIN),
                          0
                        )} à ${questionnaires
                        .filter(
                          (item, idx2) => item.NOM_CATEGORY === category_name
                        )
                        .reduce(
                          (somme, qs) => somme + Number(qs.NOTE_MAX),
                          0
                        )})`}</td>
                      <td></td>
                    </tr>
                  </table>
                </>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default FicheDevaluation;
