import React, { useEffect, useState } from "react";
import { headerData } from '../../../data/modules/my-validations-header';
import MainContainer from "../../../components/MainContainer";
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TimeTrackingService from "../../../services/TimeTrackingService";
import Message from "../../tools/Message";
import { useNavigate } from 'react-router-dom';
import { Autocomplete } from "@mui/material";
import { CircularProgress } from "@material-ui/core";

function TimeTrackingValidation(props) {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]); // Liste des ID sélectionnés
  const [selectAll, setSelectAll] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); 
  const [selectedRow, setSelectedRow] = useState(null);
  const [refusalReason, setRefusalReason] = useState(""); 
  const [refuseaff, setRefuseAffiche] = useState(false);
  const [demandeur,setDemandeur]=useState([]);
  const [form,setForm]=useState({
     demandeur:"",
     debut:"",
     fin:""
  });

  const [message, setMessage] = useState({
    open: false,
    color: 'green',
    messageInfo: '',
  });
  const { open, color, messageInfo } = message;

  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  } 

  useEffect(() => {
    setIsloading(true);
    TimeTrackingService.getlistvalidate(form.debut,form.fin,form.demandeur).then((results) => {
      if (results && results.data) {
        const rowsWithIds = results.data.map((item) => ({
          ...item,
          id: item.validationtime_id, 
        }));
        setList(rowsWithIds);
        setDemandeur([...new Set(rowsWithIds.map(item => item.demandeur))]) ;
        setIsloading(false);
      } else {
        setList([]);
        setIsloading(false);
      }
    });
  }, []);


  const handleValidateAll = () => {  
    if (selectedIds.length === 0) {
      setMessage({
        ...message, 
        open: true, 
        color: 'red', 
        messageInfo: "Veuillez sélectionner une ligne pour validation."
      });
    } else {
      setIsloading(true);
      TimeTrackingService.tousvalidate(selectedIds).then((results) => {
        if (results) {
          setMessage({ ...message, open: true, color: 'green', messageInfo: results.message });
          setIsloading(false);
          window.location.reload();
        } else {
          setIsloading(false);
          setMessage({ ...message, open: true, color: 'red', messageInfo: results.message });
        }
      });
    }
  };

  const handleOpenDialog = (row) => {
    setSelectedRow(row); 
    setOpenDialog(true); 
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); 
    setRefusalReason(""); 
    setRefuseAffiche(false);
  };

  const handlefilter = () => {
    setIsloading(true);
    TimeTrackingService.getlistvalidate(form.debut,form.fin,form.demandeur).then((results) => {
      if (results && results.data) {
        const rowsWithIds = results.data.map((item) => ({
          ...item,
          id: item.validationtime_id, 
        }));
        setList(rowsWithIds);
        setDemandeur([...new Set(rowsWithIds.map(item => item.demandeur))]) ;
        setIsloading(false);
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: "Aucune donnée ne correspond aux filtres appliqués" });
        setList([]);
        setIsloading(false);
      }
    });
  }

  const handleValidate = () => {
    TimeTrackingService.validate(selectedRow.id, selectedRow.timetraking_id).then((results) => {
      if (results) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: results.message });
        handleCloseDialog(); 
        window.location.reload();
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: results.message });
        handleCloseDialog(); 
      }
    });
  };

  const handleReject = () => {
    setRefuseAffiche(true);
  };

  const handleRejects = () => {
    TimeTrackingService.refused(selectedRow.id, refusalReason).then((results) => {
      if (results) {
        setMessage({ ...message, open: true, color: 'green', messageInfo: results.message });
        handleCloseDialog(); 
        window.location.reload();
      } else {
        setMessage({ ...message, open: true, color: 'red', messageInfo: results.message });
        handleCloseDialog(); 
      }
    });
  };

  const columns = [
    {
      field: "demandeur",
      headerName: "Nom et prénom(s)",
      width: 300,
    },
    {
      field: "date",
      headerName: "Date du pointage",
      width: 200,
    },
    {
      field: "observation",
      headerName: "Motif",
      width: 350,
    },
    {
      field: "status",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Button title="Envoyer pour validation" onClick={() => handleOpenDialog(params.row)}>
          <DoneAllIcon style={{ color: "green" }} />
        </Button>
      ),
    },
  ];

  const handleChangedemandeur = (event, newValue) => {
    setForm(prevState => ({
      ...prevState,
      demandeur: newValue
  }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setForm(prevState => ({
      ...prevState,
      [name]: value
  }));
  }

  return (
    <MainContainer {...headerData}>
      <React.Fragment>
        <Message open={open} color={color} messageInfo={messageInfo} handleClose={handleCloseMessage} />
        <h1 style={{ textAlign: 'center', color: '#4682B4' }}>Liste des pointages manuels</h1>
        <Grid container spacing={3}>
           <Grid item xs={3}>
                 <Autocomplete
                        onChange={handleChangedemandeur}
                        name="demandeur"
                        options={demandeur} 
                         getOptionLabel={(option) => option}
                        renderInput={(params) => (
                         <TextField
                           {...params}
                            fullWidth
                            label="Demandeur"
                            size="small"
                            variant="outlined"
                            InputProps={{ ...params.InputProps, type: "search" }}
                                  />
                             )}
                  />
           </Grid>
           <Grid item xs={3}>
               <TextField
                   fullWidth
                   id="outlined-basic"
                   size="small"
                   variant="outlined"
                   name="debut"
                   label="Début"
                   onChange={handleChange}
                   value={form.debut}
                   type="date"
                />
           </Grid>
           <Grid item xs={3}>
              <TextField
                   fullWidth
                   id="outlined-basic"
                   size="small"
                   variant="outlined"
                   name="fin"
                   label="Fin"
                   onChange={handleChange}
                  value={form.fin}
                   type="date"
                />
           </Grid>
           <Grid item xs={3}>
              <Button onClick={handlefilter} color="primary" variant="outlined" style={{ marginRight: '10px' }}>
                        Filtrer
               </Button>
           </Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={handleValidateAll}
              style={{
                fontWeight: 600,
                width: "90%",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: "#8FBC8F"
              }}
            >
              {isloading ? (
                                <CircularProgress size={24} style={{ color: "green", marginRight: 8 }} />
                            ) : (
                              <></>
                            )}
                            {isloading ? "Validation en cours..." : "  Tout valider"}
            
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={list}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              style={{ width: "100%", height: 500 }}
              checkboxSelection
              selectionModel={selectedIds}  
              onSelectionModelChange={(newSelection) => {
                setSelectedIds(newSelection); 
              }}
            />
          </Grid>
        </Grid>

        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="100px">
          <DialogTitle>Détails du pointage</DialogTitle>
          <DialogContent>
            {selectedRow && (
              <>
                <p><strong>Nom et Prénom:</strong> {selectedRow.demandeur}</p>
                <p><strong>Date:</strong> {selectedRow.date}</p>
                <p><strong>Observation:</strong> {selectedRow.observation}</p>
                {refuseaff && (
                  <TextField
                    label="Raison du refus"
                    value={refusalReason}
                    onChange={(e) => setRefusalReason(e.target.value)}
                    fullWidth
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                )}
                <div>
                  {!refuseaff && (
                    <>
                      <Button onClick={handleValidate} color="primary" variant="outlined" style={{ marginRight: '10px' }}>
                        Valider
                      </Button>
                      <Button onClick={handleReject} color="secondary" variant="outlined" style={{ marginRight: '10px' }}>
                        Refuser
                      </Button>
                      <Button onClick={handleCloseDialog} color="primary" variant="outlined">
                        Fermer
                      </Button>
                    </>
                  )}

                  {refuseaff && (
                    <>
                      <Button onClick={handleRejects} color="secondary" variant="outlined" style={{ marginRight: '10px' }}>
                        CONFIRMER
                      </Button>
                      <Button onClick={handleCloseDialog} color="primary" variant="outlined">
                        Fermer
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    </MainContainer>
  );
}

export default TimeTrackingValidation;
