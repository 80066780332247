import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const circuit_validation_endpoint = "CircuitValidatorController";

export const circuit_validation_service = {
  async get_default_validators_list(filtre) {
    try {
      const result = await axios.get(
        apiUrl + "/" + circuit_validation_endpoint + `/default_list`,
        { params: filtre }
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async get_personnalized_validators_list() {
    try {
      const result = await axios.get(
        apiUrl + "/" + circuit_validation_endpoint + `/personnalized_list/`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async get_function_validators_list() {
    try {
      const result = await axios.get(
        apiUrl + "/" + circuit_validation_endpoint + `/funciton_list/`
      );
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async get_one_validator(validator) {
    try {
      const result = await axios.get(
        apiUrl + "/" + circuit_validation_endpoint + `/insert_validator/`,
        validator
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async get_one_validator_by_function_id(function_id) {
    try {
      const result = await axios.get(
        apiUrl +
          "/" +
          circuit_validation_endpoint +
          `/find_personalized_validator_by_function_id/`,
        function_id
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async insert_validator(validator) {
    try {
      const result = await axios.post(
        apiUrl + "/" + circuit_validation_endpoint + `/insert/`,
        validator
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update_validator(validator) {
    try {
      const result = await axios.post(
        apiUrl + "/" + circuit_validation_endpoint + `/update_validator/`,
        validator
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async update_ultiple_validators(validators) {
    try {
      const result = await axios.post(
        apiUrl + "/" + circuit_validation_endpoint + `/update_multiple/`,
        validators
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async delete_validator(validator) {
    try {
      const result = await axios.post(
        apiUrl + "/" + circuit_validation_endpoint + `/delete/`,
        validator
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
};
