import { useState, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

function useConfirm() {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogOptions, setDialogOptions] = useState({
    title: '',
    content: '',
    onConfirm: null,
  });

  const openConfirmDialog = useCallback((title, content, onConfirm) => {
    setDialogOptions({ title, content, onConfirm });
    setIsOpen(true);
  }, []);

  const closeDialog = () => {
    setIsOpen(false);
    setDialogOptions({ title: '', content: '', onConfirm: null });
  };

  const handleConfirm = () => {
    if (dialogOptions.onConfirm) dialogOptions.onConfirm();
    closeDialog();
  };

  const DialogComponent = () => (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>{dialogOptions.title}</DialogTitle>
      <DialogContent>{dialogOptions.content}</DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">Oui</Button>
        <Button onClick={closeDialog} color="secondary">Non</Button>
      </DialogActions>
    </Dialog>
  );

  return {openConfirmDialog, DialogComponent };
}

export default useConfirm;
