import { DataGrid } from "@material-ui/data-grid";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import { Tooltip, Typography } from "@mui/material";
import { evaluation_questionnaire_service } from "../../../services/PersonalEvaluationService";
import useToast from "../../../hooks/useToast";
import { TYPE_NOTIFICATION } from "../../../components/reusable/NotificationBull";

const QuestionnaireAssignees = ({
  row_datas,
  filtre,
  handleOpenform,
  handleSetCurrentQuestionnaire,
  actualiseFormData,
}) => {
  const { Toast, showToast } = useToast();
  const handleOpenFormAjout = (questionnaire) => {
    handleSetCurrentQuestionnaire(questionnaire);
    handleOpenform();
  };

  const handleActualiseData = () => {
    actualiseFormData();
  };

  const handleDeleteQuestionnaire = (questionnaire) => {
    evaluation_questionnaire_service
      .deleteAssignedQuestionnaire(questionnaire)
      .then((res) => {
        if (res && res.success) {
          showToast(TYPE_NOTIFICATION.SUCCESS,res.message);
          handleActualiseData();
        }else{
          showToast(TYPE_NOTIFICATION.ERROR,res.message);
        }
      }).catch(e=>{
        showToast(TYPE_NOTIFICATION.ERROR,e.toString());
      });
  };

  const columns = [
    {
      field: "libelle_actions",
      headerName: "Actions",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title={"Cliquer pour enlever "}>
              <LoadingButton
              disabled={!(filtre.saison && filtre.fonction)}
                onClick={(ev) => handleDeleteQuestionnaire(params.row)}
                size="small"
                color="warning"
              >
                <ArrowBackOutlined />
              </LoadingButton>
            </Tooltip>
          </div>
        );
      },
      width: 130,
    },
    {
      field: "QUESTION",
      headerName: "Question",
      width: 350,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "NOM_SESSION",
      headerName: "Session de l'evaluation ",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: "Nom de la fonction",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Toast />
      <DataGrid
        rows={row_datas}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[100, 75, 50, 25, 10, 5]}
        disableSelectionOnClick
        getRowId={(row) =>
          row.ID_QUESTIONNAIRE +
          "~" +
          row.ID_CATEGORY +
          "~" +
          row.ID_CRITERE +
          "~" +
          row.ID_SAISON
        }
      />
    </>
  );
};

export default QuestionnaireAssignees;
