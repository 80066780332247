import { useEffect, useState } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { DataGrid } from "@material-ui/data-grid";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import UserService from "../../../services/UserService";
import EvaluationExaminationForm from "./EvaluationExaminationForm";
import EvaluationExaminationFormResume from "./EvaluationExaminationFormResume";
import LoadingSimpleBackdrop from "../../tools/LoadingSimpleBackdrop";
import { exportToExcel } from "../../../helpers/ExportHelpers";
import { evaluation_refonte_saison_service } from "../../../services/PersonalEvaluationRefonteService";

const EmployeeListTable = ({
  openForm,
  onCloseForm,
  filtre,
  handleOpenDialog,
  excelExport,
  title,
}) => {
  const [openLoadingSimpleBackdrop, setOpenLoadingSimpleBackdrop] =
    useState(false);
  const [selected_person, setSelectedPerson] = useState(null);
  const [formEvaluationOpen, setFormEvaluationOpen] = useState(false);
  const [formResumeOpen, setFormResumeOpen] = useState(false);
  const [user_list, setUserList] = useState([]);

  const columns1 = [
    {
      field: "last_name",
      headerName: "Nom de l'employée",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "first_name",
      headerName: "Prénoms de l'employée",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "function_name",
      headerName: "Fonction",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "service_code",
      headerName: "Code du service",
      width: 180,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "evaluated",
      headerName: "Evalué",
      width: 120,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "libelle_actions",
      headerName: "Actions",
      disableClickEventBulbing: true,
      renderCell: (params) => {
        return (
          <Grid pl={1} width={"100%"}>
            <Tooltip title={"Cliquer pour commencer l'evaluation "}>
              <LoadingButton
                disabled={params.row.evaluated === "NON"}
                onClick={(e) => handleStartEvaluation(params.row)}
                color="primary"
                variant="outlined"
                startIcon={<PsychologyAltOutlinedIcon />}
              >
                Evaluer
              </LoadingButton>
            </Tooltip>
            <Tooltip title={"Cliquer pour afficher l'evaluation "}>
              <LoadingButton
                onClick={(e) => handleShowEvaluationResume(params.row)}
                sx={{ ml: 1, display: "none" }}
                color="secondary"
                variant="outlined"
                startIcon={<RemoveRedEyeOutlined />}
              >
                Afficher
              </LoadingButton>
            </Tooltip>
          </Grid>
        );
      },
      width: 250,
    },
  ];

  const columns2 = [
    {
      field: "FULL_NAME",
      headerName: "Nom et prenoms",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "registration_number",
      headerName: "Matricule",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "direction_code",
      headerName: "Direction",
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "function_name",
      headerName: "Fonction",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "service_code",
      headerName: "Code du service",
      width: 180,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "SAISON_NAME",
      headerName: "Saisons",
      width: 180,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
    /* {
      field: "note_gen",
      headerName: "Note generale",
      width: 180,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    }, */
    {
      field: "CLASSIFICATION",
      headerName: "Classification",
      width: 180,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography>{params.value}</Typography>
        </Tooltip>
      ),
    },
  ];

  const [columns, setColumns] = useState(columns1);

  const handleRefreshSubordinateList = () => {
    UserService.getAllOfMySubordinate(filtre).then((res) => {
      setUserList(res.data || []);
    });
  };
  const handleRefreshColleagueList = () => {
    setOpenLoadingSimpleBackdrop(true);
    UserService.getAllOfMyColleaguesInSameService(filtre).then((res) => {
      setOpenLoadingSimpleBackdrop(false);
      setUserList(res.data || []);
    });
  };
  const handleRefreshRsultList = () => {
    setOpenLoadingSimpleBackdrop(true);
    evaluation_refonte_saison_service.getResultList(filtre).then((res) => {
      setOpenLoadingSimpleBackdrop(false);
      setUserList(res.data || []);
    });
  };

  const refreshDataTable = () => {
    if (String(title).includes("Liste des employées de même superieur")) {
      handleRefreshColleagueList();
      setColumns(columns1);
    }
    if (String(title).includes("Resultat de l'evaluation")) {
      handleRefreshRsultList();
      setColumns(columns2);
    } else {
      handleRefreshSubordinateList();
      setColumns(columns1);
    }
  };

  const handleStartEvaluation = (employee) => {
    setSelectedPerson(employee);
    handleOpenDialog();
    setFormEvaluationOpen(true);
  };
  const handleShowEvaluationResume = (employee) => {
    setSelectedPerson(employee);
    handleOpenDialog();
    setFormResumeOpen(true);
  };

  useEffect(() => {
    if (filtre) {
      refreshDataTable();
    }
    if (openForm) {
      setFormEvaluationOpen(openForm);
    }
    if (title) {
      refreshDataTable();
    }

    if (excelExport > 0) {
      exportToExcel(user_list, columns, "Liste des employees " + new Date());
    }
  }, [filtre, openForm, title, excelExport]);

  const handleCloseFormEvaluationDIalog = () => {
    onCloseForm();
    refreshDataTable();
    setFormEvaluationOpen(false);
  };

  const handleCloseFormResumeDIalog = () => {
    onCloseForm();
    refreshDataTable();
    setFormResumeOpen(false);
  };

  return (
    <>
      <LoadingSimpleBackdrop
        openLoadingSimpleBackdrop={openLoadingSimpleBackdrop}
      />
      <EvaluationExaminationForm
        candidat={selected_person}
        isOpen={formEvaluationOpen}
        handleClose={handleCloseFormEvaluationDIalog}
      />
      <EvaluationExaminationFormResume
        selected_employee={selected_person}
        isOpen={formResumeOpen}
        handleClose={handleCloseFormResumeDIalog}
      />
      <DataGrid
        rows={user_list}
        getRowId={(row) => row.user_id}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 5]}
        disableSelectionOnClick
      />
    </>
  );
};

export default EmployeeListTable;
